import { defineStore } from 'pinia';
import { ref } from 'vue';
import FetchApiClient from '@psp/pogona_fetch_authed_client';
import store from '@/store';
import {
  OsdEntryHeader,
  OsdEntryLine,
  OsdEntryLineImage,
  OsdEntryReason,
  OsdEntryStatus,
  OsdEntryLineStatus,
  OsdDistributionCenter,
  OsdEntryLineResolution,
} from '@psp/osddto';
import FileSaver from 'file-saver';
import moment from 'moment';
import querystringify from 'querystringify';

export const useOsdStore = defineStore('osdStore', () => {
  const client = ref<FetchApiClient>();
  const headers = ref<OsdHeaderInfo[]>();
  const gettingHeaders = ref(false);
  const skipheaders = ref(0);
  const takeheaders = ref(10);

  const gettingHeader = ref(false);
  const gettingLines = ref(false);
  const gettingImages = ref(false);
  const downloadingImages = ref(false);
  const savingLines = ref(false);
  const selectedHeader = ref<OsdEntryHeader>();
  const lines = ref<OsdEntryLine[]>();
  const linesImages = ref<Record<number, OsdEntryLineImage[]>>();
  const entryReasons = ref<OsdEntryReason[]>();
  const entryStatuses = ref<OsdEntryStatus[]>();
  const entryResolutions = ref<OsdEntryLineResolution[]>();
  const entryLineStatuses = ref<OsdEntryLineStatus[]>();
  const distributionCenters = ref<OsdDistributionCenter[]>();
  const buyerCodes = ref<PspBuyer[]>();
  const selectedEntryStatuses = ref<number[]>();
  const selectedEntryReasons = ref<number[]>();
  const selectedDistributionCenters = ref<number[]>();
  const selectedBuyerCodes = ref<string[]>();
  const osdHomeSearch = ref<string | undefined | null>(null);
  const lastHeaderInfoCount = ref(0);
  const closingHeader = ref(false);
  const placeCardData = ref<EntryLinePlaceCardData[]>();
  const savingHeaderSignature = ref(false);

  async function initClient(initedClient: FetchApiClient): Promise<any> {
    client.value = initedClient;
  }

  async function getEntryHeaders(sortBy: string, sortByDesc: boolean) {
    gettingHeaders.value = true;
    try {
      const queryStringData = {
        skip: skipheaders.value,
        take: takeheaders.value,
        osdSearch: osdHomeSearch.value,
        sortby: sortBy?.length > 0 ? `${sortBy} ${sortByDesc ? 'desc' : 'asc'}` : undefined,
      };
      let queryString = querystringify.stringify(queryStringData);

      const entryStatusesQuery = selectedEntryStatuses.value
        ?.map(x => `entryStatuses=${x}`)
        .join('&');
      const entryReasonsQuery =
        selectedEntryReasons.value?.map(x => `entryReasons=${x}`).join('&') ?? '';
      const distributionCentersQuery =
        selectedDistributionCenters.value?.map(x => `distributionCenters=${x}`).join('&') ?? '';
      const buyerCodesQuery = selectedBuyerCodes.value?.map(x => `buyerCodes=${x}`).join('&') ?? '';

      if (entryStatusesQuery && entryStatusesQuery.length > 0) {
        queryString += `&${entryStatusesQuery}`;
      }
      if (entryReasonsQuery && entryReasonsQuery.length > 0) {
        queryString += `&${entryReasonsQuery}`;
      }
      if (distributionCentersQuery && distributionCentersQuery.length > 0) {
        queryString += `&${distributionCentersQuery}`;
      }
      if (buyerCodesQuery && buyerCodesQuery.length > 0) {
        queryString += `&${buyerCodesQuery}`;
      }

      // set the current search params to local storage
      localStorage.setItem(
        OsdConsts.SearchLocalStorageKey,
        JSON.stringify(
          new OsdSearchParams(
            selectedEntryStatuses.value ?? [],
            selectedEntryReasons.value ?? [],
            selectedDistributionCenters.value ?? [],
            selectedBuyerCodes.value ?? [],
          ),
        ),
      );

      const headersResponse = await client.value!.get<OsdHeaderInfo[]>(
        `osd/entryheader/info?${queryString}`,
      );

      lastHeaderInfoCount.value = headersResponse.length;

      if (headers.value?.length) {
        headers.value?.push(...headersResponse);
      } else {
        headers.value = headersResponse;
      }
      gettingHeaders.value = false;
      skipheaders.value += takeheaders.value;
    } catch (err) {
      gettingHeaders.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting OSD headers.',
          id: 'b2a680e7e-3c5f-47b3-a03a-3dbcc6947976',
        },
        { root: true },
      );
    }
  }

  async function getSelectedEntryHeader(entryHeaderId: number) {
    try {
      gettingHeader.value = true;

      selectedHeader.value = await client.value!.get<OsdEntryHeader>(
        `osd/entryheader/${entryHeaderId}`,
      );

      gettingHeader.value = false;
    } catch (err) {
      gettingHeader.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting OSD header.',
          id: '16094609-230d-4d39-8f45-62a1cada077f',
        },
        { root: true },
      );
    }
  }

  async function getEntryLines(entryHeaderId: number) {
    try {
      gettingLines.value = true;

      lines.value = await client.value!.get<OsdEntryLine[]>(
        `osd/entryline?$filter=EntryHeaderId eq ${entryHeaderId}&$orderby=EntryLineId&$take=9999`,
      );
      gettingLines.value = false;
    } catch (err) {
      gettingLines.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting OSD lines.',
          id: '4842535e-5e32-4aaf-b22e-ec1ff90b00a9',
        },
        { root: true },
      );
    }
  }

  async function getEntryLineImages(entryHeaderId: number) {
    try {
      gettingImages.value = true;
      linesImages.value = await client.value!.get<Record<number, OsdEntryLineImage[]>>(
        `osd/entrylineimage/header/${entryHeaderId}`,
      );
      gettingImages.value = false;
    } catch (err) {
      gettingImages.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting line images.',
          id: 'a31dc019-9150-4533-a8d3-5de2a60529ca',
        },
        { root: true },
      );
    }
  }

  async function getEntryReasons() {
    try {
      entryReasons.value = await client.value!.get<OsdEntryReason[]>('osd/entryreason');
    } catch (err) {
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting OSD reasons.',
          id: '8ec4a472-2f53-43f2-a3db-93017fcd32af',
        },
        { root: true },
      );
    }
  }

  async function getEntryStatuses() {
    try {
      entryStatuses.value = await client.value!.get<OsdEntryStatus[]>('osd/entrystatus');
    } catch (err) {
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting OSD statuses.',
          id: '59503ad5-11be-4725-92da-17598de8cf8d',
        },
        { root: true },
      );
    }
  }

  async function getEntryResolutions() {
    try {
      entryResolutions.value =
        await client.value!.get<OsdEntryLineResolution[]>('osd/entrylineresolution');
    } catch (err) {
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting OSD resolutions.',
          id: '36d50714-d7bf-492d-88e1-ec1c076d8c29',
        },
        { root: true },
      );
    }
  }

  async function getEntryLineStatuses() {
    try {
      entryLineStatuses.value =
        await client.value!.get<OsdEntryLineStatus[]>('osd/entrylinestatus');
    } catch (err) {
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting OSD line statuses.',
          id: 'ff1a4049-9950-4d4e-9c75-a041590da2b3',
        },
        { root: true },
      );
    }
  }

  async function getDistributionCenters(force: boolean = false) {
    try {
      if (force === true || !distributionCenters.value || distributionCenters.value.length === 0) {
        distributionCenters.value =
          await client.value!.get<OsdDistributionCenter[]>('osd/distributioncenter');
      }
    } catch (err) {
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting distribution centers.',
          id: 'd08baedc-20d3-493c-9bf9-da9d7dda981f',
        },
        { root: true },
      );
    }
  }

  async function getBuyerCodes(force: boolean = false) {
    try {
      if (force === true || !buyerCodes.value || buyerCodes.value.length === 0) {
        buyerCodes.value = await client.value!.get<PspBuyer[]>('osd/pspbuyers');
      }
    } catch (err) {
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting PSP buyers.',
          id: '74e34c84-0e6d-48a2-9cbf-45e3766e7782',
        },
        { root: true },
      );
    }
  }

  async function downloadImages(entryHeaderInfo: OsdEntryHeader, entryLineInfo: OsdEntryLine) {
    try {
      downloadingImages.value = true;
      const request = new Request(
        client.value?.combineWithBasePath(
          `osd/storage/zip/${entryLineInfo.EntryHeaderId}/${entryLineInfo.EntryLineId}`,
        ) ?? '',
      );
      var response = await client.value?.sendRequest(request);

      const nonAlphaRegex = /[^A-Za-z0-9]+/g;
      const multipleUnderscoreRegex = /_{2,}/g;
      // Build the filename
      let fileName = `${entryHeaderInfo.Ponumber}_${entryLineInfo.VendorName}_${entryLineInfo.Upc}_${moment().format('YYYY_MM_DD_HH_mm_ss')}`;
      fileName = fileName.replace(nonAlphaRegex, '_').replace(multipleUnderscoreRegex, '_');

      FileSaver.saveAs(await response?.blob(), `${fileName}.zip`);

      downloadingImages.value = false;
    } catch (err) {
      downloadingImages.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting images.',
          id: 'e0961de1-4d76-4577-907d-a85e086e28ab',
        },
        { root: true },
      );
    }
  }

  async function downloadImage(blobLocation: string) {
    try {
      downloadingImages.value = true;
      const request = new Request(
        client.value?.combineWithBasePath(`osd/storage/${blobLocation}`) ?? '',
      );
      var response = await client.value?.sendRequest(request);

      const blobLocationParts = blobLocation.split('/');
      FileSaver.saveAs(await response?.blob(), blobLocationParts[blobLocationParts.length - 1]);

      downloadingImages.value = false;
    } catch (err) {
      downloadingImages.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting image.',
          id: '3e26f73e-3498-44ae-acc3-0e118475a529',
        },
        { root: true },
      );
    }
  }

  async function saveLines(
    lines: OsdEntryLine[],
    signatures: EntryLineSignature[],
    newNotes: EntryLineNotes[],
  ) {
    try {
      savingLines.value = true;

      for (const line of lines) {
        // closed?
        if (line.EntryLineStatusId === EntryLineStatus.Closed) {
          const signature = signatures.filter(x => x.EntryLineId === line.EntryLineId);
          if (signature && signature.length === 1 && signature[0].SignatureFile) {
            var data = new FormData();
            data.append('file', signature[0].SignatureFile);

            const request = new Request(
              client.value?.combineWithBasePath(
                `osd/storage/upload-line-image/${line.EntryHeaderId}/${line.EntryLineId}/true`,
              ) ?? '',
              {
                method: 'POST',
                body: data,
              },
            );
            var response = await client.value?.sendRequest(request);
            const signatureBlobLocation = await response?.text();
            line.SignatureBlobLocation = signatureBlobLocation || null;
          }
        } else {
          line.SignatureBlobLocation = null;
        }

        // add timestamp to notes
        const notes = newNotes.filter(x => x.EntryLineId === line.EntryLineId);
        if (notes && notes.length === 1 && notes[0].Notes && notes[0].Notes.length > 0) {
          line.DcNotes += `${moment().format('MM/DD/YYYY hh:mm A')} -- ${client.value?.account()?.name} -- ${notes[0].Notes.trim()}\n`;
        }

        await client.value?.put<OsdEntryLine>(`osd/entryline/${line.EntryLineId}`, line);
      }

      savingLines.value = false;
    } catch (err) {
      console.error(err);
      savingLines.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error saving lines.',
          id: 'd7becb80-cd88-44f1-a6c3-cd502fb8fcd5',
        },
        { root: true },
      );
    }
  }

  async function saveHeaderSignature(id: number, signature: File) {
    savingHeaderSignature.value = true;
    try {
      var data = new FormData();
      data.append('file', signature);

      const request = new Request(
        client.value?.combineWithBasePath(
          `osd/storage/upload-line-image/${id}/${Math.max(...(lines.value?.map(x => x.EntryLineId) ?? []))}/true`,
        ) ?? '',
        {
          method: 'POST',
          body: data,
        },
      );
      var response = await client.value?.sendRequest(request);
      const signatureBlobLocation = await response?.text();
      if (selectedHeader.value) {
        selectedHeader.value.SupervisorSignatureBlobLocation = signatureBlobLocation || null;
        await client.value?.patch(`osd/entryheader/${id}`, {
          SupervisorPrintedName: selectedHeader.value.SupervisorPrintedName,
          SupervisorSignatureBlobLocation: selectedHeader.value.SupervisorSignatureBlobLocation,
        });
      }

      savingHeaderSignature.value = false;
    } catch (err) {
      console.error(err);
      savingHeaderSignature.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error saving header signature.',
          id: 'ac359ba0-d9d9-4b92-a3e5-93de8771d0e8',
        },
        { root: true },
      );
    }
  }

  async function setEntryStatus(entryHeaderId: number, entryStatus: EntryStatus) {
    try {
      await client.value?.patch(`osd/entryheader/${entryHeaderId}`, { EntryStatusId: entryStatus });
    } catch (err) {
      console.error(err);
      savingLines.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error saving entry status.',
          id: '99ac6e36-ed2c-4bc0-8f4e-2c9018ada924',
        },
        { root: true },
      );
    }
  }

  async function closeEntryHeader(id: number) {
    closingHeader.value = true;
    try {
      await client.value!.patch(`osd/entryheader/${id}`, {
        EntryStatusId: EntryStatus.Closed,
      });
      closingHeader.value = false;
    } catch (err) {
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error closing entry.',
          id: '56e74038-2e65-4805-9884-b2a981815db6',
        },
        { root: true },
      );
      closingHeader.value = false;
    }
  }

  async function getPlacecardData(entryHeaderId: number) {
    try {
      placeCardData.value = await client.value?.get<EntryLinePlaceCardData[]>(
        `osd/entryheader/placecard/${entryHeaderId}`,
      );
    } catch (err) {
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting placecard data.',
          id: 'bce513a9-5bb3-4ccb-88c5-2875470ddb54',
        },
        { root: true },
      );
    }
  }

  function userIsOsdLeader() {
    return (client.value?.account()?.idTokenClaims?.roles?.indexOf('OSDLeader') ?? -1) > -1;
  }

  function username() {
    return client.value?.account().name;
  }

  return {
    client,
    headers,
    gettingHeaders,
    gettingImages,
    gettingHeader,
    gettingLines,
    skipheaders,
    selectedHeader,
    lines,
    entryReasons,
    entryStatuses,
    entryLineStatuses,
    entryResolutions,
    distributionCenters,
    buyerCodes,
    linesImages,
    downloadingImages,
    savingLines,
    lastHeaderInfoCount,
    selectedEntryStatuses,
    selectedEntryReasons,
    selectedDistributionCenters,
    selectedBuyerCodes,
    closingHeader,
    osdHomeSearch,
    placeCardData,
    savingHeaderSignature,
    initClient,
    getEntryHeaders,
    getSelectedEntryHeader,
    getEntryLines,
    getEntryReasons,
    getEntryStatuses,
    getEntryLineStatuses,
    getDistributionCenters,
    getBuyerCodes,
    getEntryResolutions,
    getEntryLineImages,
    downloadImages,
    downloadImage,
    saveLines,
    closeEntryHeader,
    getPlacecardData,
    setEntryStatus,
    userIsOsdLeader,
    saveHeaderSignature,
    username,
  };
});

export class EntryLineSignature {
  constructor(
    public EntryLineId: number,
    public SignatureFile: File | null,
    public HasSignature: Boolean,
  ) {}
}

export class EntryLineNotes {
  constructor(
    public EntryLineId: number,
    public Notes: string,
  ) {}
}

export class OsdHeaderInfo {
  constructor(
    public Asn: string | null,
    public CreatedByName: string,
    public CreatedDate: Date,
    public Description: string | null,
    public DistributionCenterId: number,
    public EntryHeaderId: number,
    public EntryStatusId: number,
    public PoArrivalDate: Date,
    public Ponumber: string,
    public PoRecId: number,
    public UsernameCreatedBy: string,
    public Buyers: string | null,
    public Vendors: string | null,
    public DaysSince: number,
  ) {}
}

export enum EntryStatus {
  InProgress = 1,
  PendingDcReview = 2,
  PendingBuyerReview = 3,
  Closed = 4,
}

export enum EntryLineStatus {
  Open = 1,
  Closed = 2,
}

export class OsdConsts {
  public static SearchLocalStorageKey = 'OSD_Search';
}

export class OsdSearchParams {
  constructor(
    public entryStatuses: number[],
    public entryReasons: number[],
    public distributionCenters: number[],
    public buyerCodes: string[],
  ) {}
}

export class EntryLinePlaceCardData {
  constructor(
    public CreatedDate: Date,
    public PoNumber: string,
    public Asn: string,
    public CreatedByName: string,
    public ItemNumber: string,
    public NumberOfCases: number,
    public ActualCaseCount: number,
    public TotalUnitCount: number,
    public EntryReasonId: number,
    public EntryLineId: number,
  ) {}
}

export class PspBuyer {
  constructor(
    public BuyerCode: string,
    public BuyerName: string,
  ) {}
}
