import { Record } from "../../fable-library-js.4.24.0/Types.js";
import { option_type, int32_type, class_type, record_type, string_type } from "../../fable-library-js.4.24.0/Reflection.js";
import { PrebookItemD365Data_$reflection } from "./PrebookItemD365Data.fs.js";

export class PrebookRequestForm extends Record {
    constructor(FieldName, VendorItemNumber, ShipperUPC, PrebookDescription, PrebookQty, PrebookRegularCost, PrebookSaleCost, PrebookRegularRetail, PrebookSaleRetail, PrebookSaleProfit, PrebookPSPCost, ProductUPC, ProductQty, OffInvoice, ScanBack, UnitSaleCost, UnitSaleRetail, ProductSaleProfit, DCMargin, MonthStart, MonthEnd, Season, Year, DiscountType, ShipThrough, MOQ, PspWnw, PriorityDisplay, DisplayLocation) {
        super();
        this.FieldName = FieldName;
        this.VendorItemNumber = VendorItemNumber;
        this.ShipperUPC = ShipperUPC;
        this.PrebookDescription = PrebookDescription;
        this.PrebookQty = PrebookQty;
        this.PrebookRegularCost = PrebookRegularCost;
        this.PrebookSaleCost = PrebookSaleCost;
        this.PrebookRegularRetail = PrebookRegularRetail;
        this.PrebookSaleRetail = PrebookSaleRetail;
        this.PrebookSaleProfit = PrebookSaleProfit;
        this.PrebookPSPCost = PrebookPSPCost;
        this.ProductUPC = ProductUPC;
        this.ProductQty = ProductQty;
        this.OffInvoice = OffInvoice;
        this.ScanBack = ScanBack;
        this.UnitSaleCost = UnitSaleCost;
        this.UnitSaleRetail = UnitSaleRetail;
        this.ProductSaleProfit = ProductSaleProfit;
        this.DCMargin = DCMargin;
        this.MonthStart = MonthStart;
        this.MonthEnd = MonthEnd;
        this.Season = Season;
        this.Year = Year;
        this.DiscountType = DiscountType;
        this.ShipThrough = ShipThrough;
        this.MOQ = MOQ;
        this.PspWnw = PspWnw;
        this.PriorityDisplay = PriorityDisplay;
        this.DisplayLocation = DisplayLocation;
    }
}

export function PrebookRequestForm_$reflection() {
    return record_type("pogona_vendor_domain.prebook.PrebookRequestForm", [], PrebookRequestForm, () => [["FieldName", string_type], ["VendorItemNumber", string_type], ["ShipperUPC", string_type], ["PrebookDescription", string_type], ["PrebookQty", string_type], ["PrebookRegularCost", string_type], ["PrebookSaleCost", string_type], ["PrebookRegularRetail", string_type], ["PrebookSaleRetail", string_type], ["PrebookSaleProfit", string_type], ["PrebookPSPCost", string_type], ["ProductUPC", string_type], ["ProductQty", string_type], ["OffInvoice", string_type], ["ScanBack", string_type], ["UnitSaleCost", string_type], ["UnitSaleRetail", string_type], ["ProductSaleProfit", string_type], ["DCMargin", string_type], ["MonthStart", string_type], ["MonthEnd", string_type], ["Season", string_type], ["Year", string_type], ["DiscountType", string_type], ["ShipThrough", string_type], ["MOQ", string_type], ["PspWnw", string_type], ["PriorityDisplay", string_type], ["DisplayLocation", string_type]]);
}

export class PrebookItem extends Record {
    constructor(id, LineNumber, D365Data) {
        super();
        this.id = id;
        this.LineNumber = (LineNumber | 0);
        this.D365Data = D365Data;
    }
}

export function PrebookItem_$reflection() {
    return record_type("pogona_vendor_domain.prebook.PrebookItem", [], PrebookItem, () => [["id", class_type("System.Guid")], ["LineNumber", int32_type], ["D365Data", option_type(PrebookItemD365Data_$reflection())]]);
}

