<template>
  <core-view-template title="OS & D Entry Detail">
    <v-row v-if="selectedHeader">
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-icon @click.stop.prevent="getPlacecard" style="font-size: 50px"
                  >mdi-printer</v-icon
                >
              </v-col>
            </v-row>
            <v-card-title>{{ selectedHeader.Description }}</v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col class="pb-0"> PO: {{ selectedHeader.Ponumber }} </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0"> ASN: {{ selectedHeader.Asn }} </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  PO Arrival: {{ formatters.formatDate(selectedHeader.PoArrivalDate) }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  Distribution Center:
                  {{ distributionCenterName(selectedHeader.DistributionCenterId) }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  Created By: {{ selectedHeader.CreatedByName }} |
                  {{ selectedHeader.UsernameCreatedBy }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  Created On: {{ formatters.formatDatetime(selectedHeader.CreatedDate, true) }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="py-0">
                  <v-select
                    v-model="entryStatusId"
                    :items="entryStatuses"
                    @change="entryStatusUpdate"
                    item-value="EntryStatusId"
                    item-text="Description"
                    label="Entry Status"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  selectedHeader.SupervisorSignatureBlobLocation &&
                  selectedHeader.SupervisorSignatureBlobLocation.length > 0
                "
              >
                <v-col>
                  <v-row>
                    <v-col>Supervisor:</v-col>
                  </v-row>
                  <data-authed-image
                    class="signature intials"
                    :src="`${Vue.prototype.$apiHostAndProtocol}/api/osd/storage/${selectedHeader.SupervisorSignatureBlobLocation}`"
                  />
                  <label>{{ selectedHeader.SupervisorPrintedName }}</label>
                </v-col>
              </v-row>
            </v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="gettingHeader"
      type="card-heading,text,text,text,text"
    ></v-skeleton-loader>
    <v-row v-if="lines && lines.length > 0">
      <v-col>
        <v-card v-for="(line, lix) in lines" v-bind:key="lix">
          <v-card-text>
            <v-card-title class="pb-0">Line #{{ lix + 1 }}</v-card-title>
            <v-card-title class="pt-0">
              <v-row>
                <v-col>
                  <v-row>
                    <v-col class="pb-0">
                      <span>UPC: {{ line.Upc }}{{ lockedLines[lix] ? ' (LOCKED)' : '' }}</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="lockedLines[lix]">
                    <v-col class="pt-0"><v-btn @click="unlock(line, lix)">Unlock</v-btn></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col class="pb-0"> Item #: {{ line.ItemNumber }} </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  Vendor: {{ line.VendorName }} | {{ line.VendorNumber }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 text-wrap">
                  <span>
                    {{ line.ItemDescription }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-2">
                <v-col class="py-0">
                  Cost: {{ line.Cost | formatCurrency }} | Total Cost:
                  {{ line.TotalCost | formatCurrency }}</v-col
                >
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="line.BuyerCode"
                    label="Buyer"
                    :items="buyerCodes"
                    @change="changeBuyer(line, line.BuyerCode ?? '')"
                    item-text="BuyerName"
                    item-value="BuyerCode"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-subtitle>

            <v-card class="mb-3">
              <v-card-text>
                <v-card-title>Line Details</v-card-title>
                <v-row>
                  <v-col>
                    <v-row>
                      <v-col> Number of cases <label class="text--red">*</label> </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-icon
                          @click="minus('NumberOfCases', line)"
                          color="black"
                          :disabled="lockedLines[lix]"
                          >mdi-minus</v-icon
                        >
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="line.NumberOfCases"
                          :disabled="lockedLines[lix]"
                          @change="countChange('NumberOfCases', line)"
                          inputmode="numeric"
                          style="width: 50px"
                          class="d-inline-block pt-0 mt-0 centered-input"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-icon
                          @click="plus('NumberOfCases', line)"
                          :disabled="lockedLines[lix]"
                          color="black"
                          >mdi-plus</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col> Actual case count <label class="text--red">*</label> </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-icon
                          @click="minus('ActualCaseCount', line)"
                          :disabled="lockedLines[lix]"
                          color="black"
                          >mdi-minus</v-icon
                        >
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="line.ActualCaseCount"
                          :disabled="lockedLines[lix]"
                          @change="countChange('ActualCaseCount', line)"
                          inputmode="numeric"
                          style="width: 50px"
                          class="d-inline-block pt-0 mt-0 centered-input"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-icon
                          @click="plus('ActualCaseCount', line)"
                          :disabled="lockedLines[lix]"
                          color="black"
                          >mdi-plus</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col> Total unit count <label class="text--red">*</label> </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-icon
                          @click="minus('TotalUnitCount', line)"
                          :disabled="lockedLines[lix]"
                          color="black"
                          >mdi-minus</v-icon
                        >
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="line.TotalUnitCount"
                          :disabled="lockedLines[lix]"
                          @change="countChange('TotalUnitCount', line)"
                          inputmode="numeric"
                          style="width: 50px"
                          class="d-inline-block pt-0 mt-0 centered-input"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-icon
                          @click="plus('TotalUnitCount', line)"
                          :disabled="lockedLines[lix]"
                          color="black"
                          >mdi-plus</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="line.EntryReasonId"
                          :items="entryReasons"
                          :disabled="lockedLines[lix]"
                          item-value="EntryReasonId"
                          item-text="Description"
                        >
                          <template v-slot:label>
                            Reason <label class="text--red">*</label>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col>
                        <v-tooltip bottom :disabled="store.userIsOsdLeader() === true">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <v-select
                                v-model="line.EntryLineStatusId"
                                v-on="on"
                                v-bind="attrs"
                                :items="entryLineStatuses"
                                :disabled="lockedLines[lix] || store.userIsOsdLeader() === false"
                                item-value="EntryLineStatusId"
                                item-text="Description"
                                @change="statusChanged(line)"
                              >
                                <template v-slot:label>
                                  Status <label class="text--red">*</label>
                                </template>
                              </v-select>
                            </div>
                          </template>
                          <span>OSD leader role is required to update the status.</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-row
                      v-if="
                        linesImages &&
                        linesImages[line.EntryLineId] &&
                        linesImages[line.EntryLineId].length > 0
                      "
                    >
                      <v-col>
                        <v-row v-if="linesImages[line.EntryLineId].length > 1">
                          <v-col>
                            <v-btn
                              @click="downloadImages(line.EntryLineId)"
                              :disabled="downloadingImages"
                              :loading="downloadingImages"
                              >Download Photos</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            v-for="(image, imageIx) in linesImages[line.EntryLineId]"
                            v-bind:key="imageIx"
                            cols="4"
                          >
                            <v-dialog
                              :max-width="
                                loadingImagesForDialog &&
                                loadingImagesForDialog[image.EntryLineImageId] !== false
                                  ? '100px'
                                  : '800px'
                              "
                            >
                              <template v-slot:activator="{ on }">
                                <v-row>
                                  <v-col class="mb-10">
                                    <v-btn
                                      v-if="linesImages[line.EntryLineId].length === 1"
                                      @click="downloadImage(image.BlobLocation)"
                                      :disabled="downloadingImages"
                                      :loading="downloadingImages"
                                      >Download Photo</v-btn
                                    >
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col>
                                    <data-authed-image
                                      v-on="on"
                                      @click="openImageDialog(image.EntryLineImageId)"
                                      :src="`${Vue.prototype.$apiHostAndProtocol}/api/osd/storage/${image.BlobLocation}`"
                                      class="pointer"
                                      style="max-width: 400px"
                                    />
                                  </v-col>
                                </v-row>
                              </template>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular
                                  v-if="loadingImagesForDialog[image.EntryLineImageId] !== false"
                                  indeterminate
                                  color="primary"
                                  class="center"
                                  size="100"
                                  :style="
                                    loadingImagesForDialog[image.EntryLineImageId] !== false
                                      ? 'overflow: hidden;background-color:white;'
                                      : ''
                                  "
                                ></v-progress-circular>
                              </v-row>

                              <data-authed-image
                                @load="loaded(image.EntryLineImageId)"
                                :src="`${Vue.prototype.$apiHostAndProtocol}/api/osd/storage/${image.BlobLocation}`"
                              />
                            </v-dialog>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col><span>No photos</span></v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-row v-if="line.DcNotes && line.DcNotes.length > 0">
                      <v-row>
                        <v-col>
                          <span class="ml-3">Current notes:</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <span class="text--black" v-html="formatNotes(line.DcNotes)"></span>
                        </v-col>
                      </v-row>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea
                          v-if="newNotes[lix]"
                          v-model="newNotes[lix].Notes"
                          :disabled="lockedLines[lix]"
                          counter
                          :rules="[
                            v =>
                              v.length + (line.DcNotes?.length ?? 0) <= 1024 ||
                              'Max 1024 characters',
                          ]"
                        >
                          <template v-slot:label>Add Notes</template>
                          <template v-slot:counter>
                            {{
                              (line.DcNotes?.length ?? 0) + (newNotes[lix]?.Notes.length ?? 0)
                            }}/1024
                          </template>
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row v-if="line.EntryLineStatusId === EntryLineStatus.Closed">
              <v-col>
                <v-card>
                  <v-card-text>
                    <v-card-title>Resolution Details</v-card-title>
                    <v-row>
                      <v-col class="pb-0">
                        <label>Supervisor Printed Name <label class="text--red">*</label></label>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="line.PrintedName"
                          :disabled="lockedLines[lix] || (line.PrintedName?.length ?? 0) > 0"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>Signature <label class="text--red">*</label></v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <data-authed-image
                          v-if="line.SignatureBlobLocation && line.SignatureBlobLocation.length > 0"
                          class="signature"
                          :src="`${Vue.prototype.$apiHostAndProtocol}/api/osd/storage/${line.SignatureBlobLocation}`"
                        />
                        <data-signature
                          v-else-if="signatureImages[lix]"
                          :signatureImageFile.sync="signatureImages[lix].SignatureFile"
                          :hasSignature.sync="signatureImages[lix].HasSignature"
                          :signaturePadWidth="500"
                          :signaturePadHeight="250"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="line.EntryLineResolutionId"
                          :items="entryResolutions"
                          :disabled="lockedLines[lix]"
                          item-value="EntryLineResolutionId"
                          item-text="Description"
                        >
                          <template v-slot:label>
                            Resolution <label class="text--red">*</label>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea
                          v-model="line.Resolution"
                          :disabled="lockedLines[lix]"
                          counter
                          :rules="[v => v.length <= 1024 || 'Max 1024 characters']"
                        >
                          <template v-slot:label
                            >Resolution notes<label class="text--red">*</label></template
                          >
                          <template v-slot:counter>
                            {{ line.Resolution?.length ?? 0 }}/1024
                          </template>
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <data-date-picker
                          :value="resolutionDateString(line)"
                          @input="resolutionDateUpdate(line, $event)"
                          label="Resolution Date *"
                          :clearable="true"
                          :max="moment().format('YYYY-MM-DD')"
                          :disabled="lockedLines[lix]"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else-if="gettingLines === false">
      <v-col>
        <span>No lines for this entry.</span>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="gettingLines"
      type="card-heading,card-heading,text,text,text,text,card,button"
    ></v-skeleton-loader>
    <v-row v-if="gettingHeader === false && gettingLines === false">
      <v-col>
        <v-btn @click="saveLines" :disabled="saveDisabled || savingLines" :loading="savingLines"
          >Save</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="saveDisabled && saveDisabledMessages.length > 0">
      <v-col>
        <span class="text--red">Errors:</span>
        <v-list>
          <v-list-item v-for="(msg, msgIx) in saveDisabledMessages" v-bind:key="msgIx" dense>
            <span class="text--red">{{ msg }}</span>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmCloseHeader" max-width="400px" persistant>
      <v-card>
        <v-card-title>Close entry</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              >All lines on this entry are now closed. Would you like to close the entry?</v-col
            >
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col
              ><v-btn @click="closeEntry" :loading="closingHeader" :disabled="closingHeader"
                >Yes</v-btn
              ></v-col
            >
            <v-col
              ><v-btn @click="confirmCloseHeader = false" :disabled="closingHeader"
                >No</v-btn
              ></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <data-report-dialog
      v-model="reportDialog"
      :reportData="reportData"
      templateName="/osd-placecard/main"
      :loading.sync="loadingReportData"
      :supportsExcel="false"
      @snackbar-error="$emit('snackbar-error', $event)"
    ></data-report-dialog>
    <v-dialog v-model="supervisorSignatureDialog" max-width="600">
      <v-card>
        <v-card-title>Supervisor Signature</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>Once you have reviewed all the line entries, please sign your name below.</v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-row v-if="selectedHeader">
                    <v-col>
                      <v-text-field
                        v-model="selectedHeader.SupervisorPrintedName"
                        @keypress.stop=""
                      >
                        <template v-slot:label>
                          Supervisor Printed Name
                          <label class="text-red">*</label>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="selectedHeader">
                    <v-col>
                      <label>Supervisor Signature<label class="text-red">*</label></label>
                      <data-signature
                        ref="signature-pad"
                        :signatureImageFile.sync="signatureFile"
                        :hasSignature.sync="hasSignature"
                        :blob-location="selectedHeader.SupervisorSignatureBlobLocation"
                        button-text="Clear Signature"
                        fileName="supervisorSignature.png"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col
              ><v-btn
                @click="saveSignature"
                :disabled="needsSignature"
                :loading="savingHeaderSignature"
                >Save</v-btn
              ></v-col
            >
            <v-col><v-btn @click="cancelSignature">Cancel</v-btn></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </core-view-template>
</template>

<script setup lang="ts">
import Vue, { defineComponent, onMounted, ref, nextTick, computed, watch } from 'vue';
import {
  useOsdStore,
  EntryLineSignature,
  EntryLineStatus,
  EntryStatus,
  EntryLineNotes,
} from '@/stores/osdstore';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router/composables';
import { useFormatters } from '@/composables/formatters';
import { OsdEntryLine } from '@psp/osddto';
import moment from 'moment';

defineComponent({
  name: 'OsdEntryDetail',
});

const emit = defineEmits(['snackbar-success']);

const store = useOsdStore();
const route = useRoute();
const formatters = useFormatters();

const {
  selectedHeader,
  lines,
  entryReasons,
  entryStatuses,
  entryLineStatuses,
  distributionCenters,
  linesImages,
  downloadingImages,
  savingLines,
  gettingHeader,
  gettingLines,
  closingHeader,
  placeCardData,
  buyerCodes,
  entryResolutions,
  savingHeaderSignature,
} = storeToRefs(store);

const loadingImagesForDialog = ref<Array<boolean>>([] as boolean[]);
const signatureImages = ref<Array<EntryLineSignature>>([] as EntryLineSignature[]);
const lockedLines = ref<Array<boolean>>([] as boolean[]);
const newNotes = ref<Array<EntryLineNotes>>([] as EntryLineNotes[]);
const saveDisabled = ref(false);
const saveDisabledMessages = ref<Array<string>>([] as string[]);
const confirmCloseHeader = ref(false);
const reportDialog = ref(false);
const loadingReportData = ref(false);
const reportData = ref<any>({});
const entryStatusId = ref<number>(0);
const supervisorSignatureDialog = ref(false);
const signatureFile = ref<File>();
const hasSignature = ref(false);

let entryHeaderId = 0;

onMounted(async () => {
  entryHeaderId = Number(route.params.entryHeaderId);
  await store.getSelectedEntryHeader(entryHeaderId);
  entryStatusId.value = selectedHeader.value?.EntryStatusId ?? 0;

  await Promise.all([
    store.getEntryLines(entryHeaderId),
    store.getEntryLineImages(entryHeaderId),
    store.getEntryReasons(),
    store.getEntryStatuses(),
    store.getEntryLineStatuses(),
    store.getDistributionCenters(),
    store.getBuyerCodes(),
    store.getEntryResolutions(),
  ]);

  setExtraDataAndLockedLines();
});

function setExtraDataAndLockedLines() {
  signatureImages.value = [];
  newNotes.value = [];
  lockedLines.value = [];

  for (const line of lines.value ?? []) {
    signatureImages.value.push(new EntryLineSignature(line.EntryLineId, null, false));
    newNotes.value.push(new EntryLineNotes(line.EntryLineId, ''));
    lockedLines.value.push(line.EntryLineStatusId === EntryLineStatus.Closed);
  }
}

function minus(key: string, line: OsdEntryLine) {
  if (line[key] > 1 && line.EntryLineStatusId !== EntryLineStatus.Closed) {
    line[key] = Number(line[key]) - 1;

    // recalc cost if key is TotalUnitCount
    if (key === 'TotalUnitCount' && line.TotalUnitCount && line.Cost) {
      line.TotalCost = calcCost(line.TotalUnitCount, line.Cost);
    }
  }
}
function plus(key: string, line: OsdEntryLine) {
  if (line.EntryLineStatusId !== EntryLineStatus.Closed) {
    line[key] = Number(line[key]) + 1;

    // recalc cost if key is TotalUnitCount
    if (key === 'TotalUnitCount' && line.TotalUnitCount && line.Cost) {
      line.TotalCost = calcCost(line.TotalUnitCount, line.Cost);
    }
  }
}

function countChange(key: string, line: OsdEntryLine) {
  if (isNaN(Number(line[key]))) {
    line[key] = 1;
  }

  if (line.TotalUnitCount && line.Cost) {
    line.TotalCost = calcCost(line.TotalUnitCount, line.Cost);
  }
}

function calcCost(totaUnitCount: number, cost: number) {
  return Number((totaUnitCount * cost).toFixed(2));
}

function openImageDialog(entryLineImageId: number) {
  // only show the loading if the image has never been opened
  if (
    loadingImagesForDialog.value[entryLineImageId] === undefined ||
    loadingImagesForDialog.value[entryLineImageId] === null
  ) {
    Vue.set(loadingImagesForDialog.value, entryLineImageId, true);
  }
}

function loaded(entryLineImageId: number) {
  nextTick(() => {
    Vue.set(loadingImagesForDialog.value, entryLineImageId, false);
  });
}

async function downloadImages(entryLineId: number) {
  if (selectedHeader.value) {
    const line = lines.value?.filter(x => x.EntryLineId === entryLineId)[0] as OsdEntryLine;
    await store.downloadImages(selectedHeader.value, line);
  }
}

async function downloadImage(blobLocation: string) {
  await store.downloadImage(blobLocation);
}

async function saveLines() {
  if (lines.value) {
    await store.saveLines(lines.value, signatureImages.value, newNotes.value);
    await store.getEntryLines(entryHeaderId);
    setExtraDataAndLockedLines();
    emit('snackbar-success', 'Saved entry');
  }

  // check if every line is closed and ask the user if we should close the header.
  confirmCloseHeader.value =
    lines.value?.length ===
      lines.value?.filter(x => x.EntryLineStatusId === EntryLineStatus.Closed).length &&
    selectedHeader.value?.EntryStatusId !== EntryStatus.Closed;
}

function statusChanged(line: OsdEntryLine) {
  if (line && line.EntryLineStatusId !== EntryLineStatus.Closed) {
    line.ResolutionDate = new Date();
    line.SignatureBlobLocation = null;
    line.PrintedName = null;
  } else if (line && line.EntryLineStatusId === EntryLineStatus.Closed) {
    line.PrintedName = store.client?.account().name ?? '';
  }
}

function resolutionDateUpdate(line: OsdEntryLine, e: any) {
  line.ResolutionDate = moment(e).toDate();
}

function resolutionDateString(line: OsdEntryLine) {
  if (!line.ResolutionDate) {
    line.ResolutionDate = new Date();
  }
  return moment(line.ResolutionDate).format('YYYY-MM-DD');
}

function unlock(line: OsdEntryLine, lix: number) {
  line.EntryLineStatusId = EntryLineStatus.Open;
  line.ResolutionDate = new Date();
  line.SignatureBlobLocation = null;

  lockedLines.value[lix] = false;

  validateInputs();
}

async function closeEntry() {
  if (selectedHeader.value) {
    selectedHeader.value.EntryStatusId = EntryStatus.Closed;
  }
  await store.closeEntryHeader(entryHeaderId);
  confirmCloseHeader.value = false;
}

function distributionCenterName(distributionCenterId: number) {
  if (distributionCenters.value && distributionCenters.value.length > 0) {
    const distributionCenter = distributionCenters.value.filter(
      x => x.DistributionCenterId == distributionCenterId,
    );
    if (distributionCenter && distributionCenter.length === 1) {
      return distributionCenter[0].Description;
    }
  }
  return '';
}

async function entryStatusUpdate(e: any) {
  // do we need the supervisor signature for this change?
  if (
    selectedHeader.value?.EntryStatusId === EntryStatus.InProgress &&
    entryStatusId.value > EntryStatus.InProgress
  ) {
    // show dialog
    selectedHeader.value.SupervisorPrintedName = store.username() ?? '';
    supervisorSignatureDialog.value = true;
  } else {
    await store.setEntryStatus(selectedHeader.value?.EntryHeaderId ?? 0, entryStatusId.value);
  }
}

async function saveSignature() {
  if (signatureFile.value) {
    await store.saveHeaderSignature(selectedHeader.value?.EntryHeaderId ?? 0, signatureFile.value);
    await store.setEntryStatus(selectedHeader.value?.EntryHeaderId ?? 0, entryStatusId.value);
    supervisorSignatureDialog.value = false;
  }
}

function cancelSignature() {
  entryStatusId.value = selectedHeader.value?.EntryStatusId ?? 0;
}

async function getPlacecard() {
  reportDialog.value = true;
  loadingReportData.value = true;

  await saveLines();
  await store.getPlacecardData(selectedHeader.value?.EntryHeaderId ?? 0);

  reportData.value = { items: placeCardData.value };
  loadingReportData.value = false;
}

function validateInputs() {
  saveDisabled.value = !lines.value || lines.value.length === 0;
  saveDisabledMessages.value = [];

  if (lines.value && lines.value.length > 0) {
    let lix = 0;
    for (const line of lines.value) {
      if (!line.NumberOfCases || line.NumberOfCases === 0) {
        saveDisabled.value = true;
        saveDisabledMessages.value.push(`Number of cases required on line ${lix + 1}.`);
      }
      if (!line.TotalUnitCount || line.TotalUnitCount === 0) {
        saveDisabled.value = true;
        saveDisabledMessages.value.push(`Total unit count required on line ${lix + 1}.`);
      }
      if (!line.EntryReasonId || line.EntryReasonId === 0) {
        saveDisabled.value = true;
        saveDisabledMessages.value.push(`Entry reason required on line ${lix + 1}.`);
      }
      if (!line.EntryLineStatusId || line.EntryLineStatusId === 0) {
        saveDisabled.value = true;
        saveDisabledMessages.value.push(`Status required on line ${lix + 1}.`);
      }
      if ((line.DcNotes?.length ?? 0) > 1024) {
        saveDisabled.value = true;
        saveDisabledMessages.value.push(`Notes too long on line ${lix + 1}.`);
      }

      if (line.EntryLineStatusId === 2) {
        if (
          ((signatureImages.value[lix] && signatureImages.value[lix].HasSignature === false) ||
            !signatureImages.value[lix]) &&
          (line.SignatureBlobLocation === null || (line.SignatureBlobLocation?.length ?? 0) === 0)
        ) {
          saveDisabled.value = true;
          saveDisabledMessages.value.push(`Signature required on line ${lix + 1}.`);
        }
        if (!line.PrintedName || line.PrintedName.trim().length === 0) {
          saveDisabled.value = true;
          saveDisabledMessages.value.push(`Printed name required on line ${lix + 1}.`);
        }
        if (!line.Resolution || line.Resolution.trim().length === 0) {
          saveDisabled.value = true;
          saveDisabledMessages.value.push(`Resolution of issue required on line ${lix + 1}.`);
        } else if ((line.Resolution?.length ?? 0) > 1024) {
          saveDisabled.value = true;
          saveDisabledMessages.value.push(`Resolution of issue too long on line ${lix + 1}.`);
        }
        if (!line.ResolutionDate) {
          saveDisabled.value = true;
          saveDisabledMessages.value.push(`Resolution date required on line ${lix + 1}.`);
        }
        if (!line.EntryLineResolutionId || line.EntryLineResolutionId === 0) {
          saveDisabled.value = true;
          saveDisabledMessages.value.push(`Resolution status required on line ${lix + 1}.`);
        }
      }

      lix++;
    }
  }
}

async function changeBuyer(line: OsdEntryLine, buyerCode: string) {
  const buyer = buyerCodes.value?.filter(x => x.BuyerCode === buyerCode);
  if (buyer && buyer.length > 0) {
    line.BuyerCode = buyer[0].BuyerCode;
    line.BuyerName = buyer[0].BuyerName;
    line.UserManuallySetBuyer = true;
  }
}

function formatNotes(notes: string) {
  if (notes && notes.length > 0) {
    return notes.replace(/\n/g, '<br/>');
  }
  return '';
}

const needsSignature = computed(() => {
  return (
    (hasSignature.value == false &&
      (!selectedHeader.value?.SupervisorSignatureBlobLocation ||
        selectedHeader.value?.SupervisorSignatureBlobLocation.length === 0)) ||
    (selectedHeader.value?.SupervisorPrintedName?.trim() ?? '').length === 0
  );
});

watch(
  () => lines.value,
  () => {
    validateInputs();
  },
  { deep: true },
);

watch(
  () => signatureImages.value,
  () => {
    validateInputs();
  },
  { deep: true },
);
</script>

<style scoped>
.text--red {
  color: red;
}
.signature {
  border: 2px solid black;
  max-width: 500px;
}
.intials {
  max-width: 300px;
}
.center {
  margin: auto;
}
.v-dialog .v-image {
  background-color: white;
}
</style>

<style>
.theme--light.v-input--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea,
.theme--light.v-select.v-input--is-disabled .v-select__selections,
.theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.7) !important;
}
</style>
