import { Record } from "../../fable-library-js.4.24.0/Types.js";
import { record_type, list_type, string_type } from "../../fable-library-js.4.24.0/Reflection.js";
import { PrebookMonthSeason, PrebookMonthSeason_$reflection } from "./PrebookRequest.fs.js";
import { choose, toArray, filter, sortBy, cons, exists, empty } from "../../fable-library-js.4.24.0/List.js";
import { FSharpResult$2 } from "../../fable-library-js.4.24.0/Result.js";
import { comparePrimitives } from "../../fable-library-js.4.24.0/Util.js";

export class SeasonLookup extends Record {
    constructor(id, LookupId, Seasons) {
        super();
        this.id = id;
        this.LookupId = LookupId;
        this.Seasons = Seasons;
    }
}

export function SeasonLookup_$reflection() {
    return record_type("pogona_vendor_domain.prebook.SeasonLookup", [], SeasonLookup, () => [["id", string_type], ["LookupId", string_type], ["Seasons", list_type(PrebookMonthSeason_$reflection())]]);
}

export class SeasonTextRecord extends Record {
    constructor(Text$, Value) {
        super();
        this.Text = Text$;
        this.Value = Value;
    }
}

export function SeasonTextRecord_$reflection() {
    return record_type("pogona_vendor_domain.prebook.SeasonTextRecord", [], SeasonTextRecord, () => [["Text", string_type], ["Value", PrebookMonthSeason_$reflection()]]);
}

export function SeasonLookupFunctions_initSeasonLookupWithSeasons(seasons) {
    return new SeasonLookup("SeasonLookup", "SeasonLookup", seasons);
}

export function SeasonLookupFunctions_initSeasonLookup() {
    return SeasonLookupFunctions_initSeasonLookupWithSeasons(empty());
}

export function SeasonLookupFunctions_textToSeason(seasonText) {
    return new PrebookMonthSeason(1, [seasonText]);
}

export function SeasonLookupFunctions_seasonExists(seasons, seasonText) {
    return exists((_arg) => {
        let matchResult, s_1;
        if (_arg.tag === 1) {
            if (_arg.fields[0] === seasonText) {
                matchResult = 0;
                s_1 = _arg.fields[0];
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0:
                return true;
            default:
                return false;
        }
    }, seasons.Seasons);
}

export function SeasonLookupFunctions_addSeason(seasons, season) {
    if (season.tag === 1) {
        const seasonText = season.fields[0];
        if (exists((_arg) => {
            let matchResult, s_1;
            if (_arg.tag === 1) {
                if (_arg.fields[0] === seasonText) {
                    matchResult = 0;
                    s_1 = _arg.fields[0];
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0:
                    return true;
                default:
                    return false;
            }
        }, seasons.Seasons)) {
            return new FSharpResult$2(0, [seasons]);
        }
        else {
            const updatedSeasons = new SeasonLookup(seasons.id, seasons.LookupId, cons(new PrebookMonthSeason(1, [seasonText]), seasons.Seasons));
            return new FSharpResult$2(0, [new SeasonLookup(updatedSeasons.id, updatedSeasons.LookupId, sortBy((_arg_1) => {
                if (_arg_1.tag === 0) {
                    return "";
                }
                else {
                    return _arg_1.fields[0];
                }
            }, updatedSeasons.Seasons, {
                Compare: comparePrimitives,
            }))]);
        }
    }
    else {
        return new FSharpResult$2(1, ["Should be a Season"]);
    }
}

export function SeasonLookupFunctions_deleteSeason(seasons, season) {
    if (season.tag === 1) {
        return new FSharpResult$2(0, [new SeasonLookup(seasons.id, seasons.LookupId, filter((_arg) => {
            let matchResult, s_1;
            if (_arg.tag === 1) {
                if (_arg.fields[0] === season.fields[0]) {
                    matchResult = 0;
                    s_1 = _arg.fields[0];
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0:
                    return false;
                default:
                    return true;
            }
        }, seasons.Seasons))]);
    }
    else {
        return new FSharpResult$2(1, ["Should be a Season"]);
    }
}

export function SeasonLookupFunctions_getSeasonTextArray(seasons) {
    return toArray(choose((_arg) => {
        if (_arg.tag === 1) {
            const text = _arg.fields[0];
            return new SeasonTextRecord(text, new PrebookMonthSeason(1, [text]));
        }
        else {
            return undefined;
        }
    }, seasons.Seasons));
}

