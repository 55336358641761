import {
  INewItemRequestApi,
  NewItemTemplateFile,
} from '@psp/pogona_vendor_api_lib/pogona_vendor_api_lib/src';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ClientAuthenticatedAdalApiClient } from '@psp/pogona_adal_js';
import { VendorPortalFinanceInitArgs } from '@/utils/VendorPortalFinanceInitArgs';
import {
  callCreateBatch,
  callGetNewItemTemplates,
  callPerformCommands,
  vendorApiClient,
} from '@/utils/VendorApiClient.fs.js';
import store from '@/store';
import { getFilenameFromContentDisposition } from '@/utils/Utils';
import FileSaver from 'file-saver';
import { Batch, NewItemRequest } from '@psp/pogona_vendor_domain';
import { mapOption } from '@/utils/FableUtils.fs.js';
import { NewItemRequestData } from '@/utils/VendorPortalData';
import { createItemUpdateCommand } from '@/utils/NewItemTools.fs.js';

function constructBaseUrl(initArgs: VendorPortalFinanceInitArgs): string {
  return initArgs.OverrideUrl.length > 0 ? initArgs.OverrideUrl : initArgs.Origin;
}

export async function getNewItemClientOrThrow(
  getClient: (() => Promise<INewItemRequestApi>) | null,
): Promise<INewItemRequestApi> {
  if (getClient === null) {
    throw new Error('Client is not initialized');
  }
  return getClient();
}

export const useVpNewItemStore = defineStore('vpNewItemStore', () => {
  const newItemTemplates = ref<NewItemTemplateFile[]>([]);
  const authClient = ref<ClientAuthenticatedAdalApiClient | null>(null);
  const getClient = ref<(() => Promise<INewItemRequestApi>) | null>(null);
  const newItemRequest = ref<NewItemRequest | null>(null);

  function init(args: VendorPortalFinanceInitArgs) {
    authClient.value = args.AuthClient;
    const getToken = async (client: ClientAuthenticatedAdalApiClient) => {
      return client.client.getToken() as Promise<string>;
    };
    const baseUrl = constructBaseUrl(args);
    getClient.value = async () => {
      return vendorApiClient(baseUrl, () => getToken(authClient.value));
    };
  }

  async function getNewItemTemplates() {
    try {
      const client = await getNewItemClientOrThrow(getClient.value);
      const templates = await callGetNewItemTemplates(client);
      newItemTemplates.value = templates;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: 'Failed to get new item templates',
        id: '63d31759-4f44-460a-b895-90ef5c5c24cb',
      });
      throw error;
    }
  }

  async function getNewItemTemplateBlob(blobName: string) {
    try {
      const response = await authClient.value?.client.http.get(
        `/vendorapi/inewitemrequestapi/getnewitemtemplatebyname/${encodeURIComponent(blobName)}`,
        { responseType: 'blob' },
      );
      const fileName = getFilenameFromContentDisposition(response.headers['content-disposition']);
      FileSaver.saveAs(response.data, fileName ?? 'new_item_template.xlsx');
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: 'Failed to get new item template blob',
        id: '1387d49a-00ee-44c6-9d90-2232a57896a6',
      });
      throw error;
    }
  }

  async function uploadNewItemTemplate(file: File) {
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);
      await authClient.value?.client.http.post(
        '/vendorapi/inewitemrequestapi/uploadnewitemtemplate',
        formData,
      );
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: 'Failed to upload new item template',
        id: 'b44d3b58-b42b-4ae6-abcd-f32ba5ab061f',
      });
      throw error;
    }
  }

  async function getCurrentNewItemTemplate() {
    try {
      const response = await authClient.value?.client.http.get(
        `/vendorapi/inewitemrequestapi/getcurrentnewitemtemplate`,
        { responseType: 'blob' },
      );
      const fileName = getFilenameFromContentDisposition(response.headers['content-disposition']);
      FileSaver.saveAs(response.data, fileName ?? 'new_item_template.xlsx');
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: 'Failed to get current new item template',
        id: '63ec0f98-8782-4713-8b97-4d6e0afdd4f8',
      });
      throw error;
    }
  }

  async function getNewItemRequestById(aggregateId: string) {
    try {
      const client = await getNewItemClientOrThrow(getClient.value);
      const response = await client.getNewItemRequestById(aggregateId);
      newItemRequest.value = mapOption(response);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Failed to get new item request by id ${aggregateId}`,
        id: 'a2241b6e-9b33-40ee-8f16-65739b1be31c',
      });
      throw error;
    }
  }

  async function createItemUpdate(data: NewItemRequestData) {
    try {
      const command = createItemUpdateCommand(data);
      const client = await getNewItemClientOrThrow(getClient.value);
      return await callPerformCommands(command, client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: 'Failed to create item update',
        id: '677a6319-bce5-41e3-a31f-612108689246',
      });
      throw error;
    }
  }

  async function createBatch(batch: Batch) {
    try {
      const client = await getNewItemClientOrThrow(getClient.value);
      return await callCreateBatch(batch, client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: 'Failed to create batch',
        id: '31161e48-e2c9-4cec-b05f-20903d60e350',
      });
      throw error;
    }
  }

  return {
    newItemTemplates,
    newItemRequest,
    init,
    getNewItemTemplates,
    getNewItemTemplateBlob,
    uploadNewItemTemplate,
    getCurrentNewItemTemplate,
    getNewItemRequestById,
    createItemUpdate,
    createBatch,
  };
});
