import { Record } from "../fable-library-js.4.24.0/Types.js";
import { record_type, int32_type, tuple_type, bool_type, option_type, class_type, string_type } from "../fable-library-js.4.24.0/Reflection.js";

export class PrebookRequestSearchParametersDto extends Record {
    constructor(Search, CreatedBy, CreatedDateFrom, CreatedDateTo, SortByCategoryManager, SortByCreatedDate, SortByMonthSeason, SortByYear, Skip, Take) {
        super();
        this.Search = Search;
        this.CreatedBy = CreatedBy;
        this.CreatedDateFrom = CreatedDateFrom;
        this.CreatedDateTo = CreatedDateTo;
        this.SortByCategoryManager = SortByCategoryManager;
        this.SortByCreatedDate = SortByCreatedDate;
        this.SortByMonthSeason = SortByMonthSeason;
        this.SortByYear = SortByYear;
        this.Skip = (Skip | 0);
        this.Take = (Take | 0);
    }
}

export function PrebookRequestSearchParametersDto_$reflection() {
    return record_type("pogona_vendor_api_lib.PrebookDtos.PrebookRequestSearchParametersDto", [], PrebookRequestSearchParametersDto, () => [["Search", string_type], ["CreatedBy", string_type], ["CreatedDateFrom", option_type(class_type("System.DateTime"))], ["CreatedDateTo", option_type(class_type("System.DateTime"))], ["SortByCategoryManager", tuple_type(bool_type, bool_type)], ["SortByCreatedDate", tuple_type(bool_type, bool_type)], ["SortByMonthSeason", tuple_type(bool_type, bool_type)], ["SortByYear", tuple_type(bool_type, bool_type)], ["Skip", int32_type], ["Take", int32_type]]);
}

export function initPrebookRequestSearchParametersDto() {
    return new PrebookRequestSearchParametersDto("*", "", undefined, undefined, [false, false], [true, false], [false, false], [false, false], 0, 10);
}

export class PrebookRequestSearchDocumentDto extends Record {
    constructor(id, CategoryManager, MonthSeason, Year, Status, CreatedDate, CreatedBy) {
        super();
        this.id = id;
        this.CategoryManager = CategoryManager;
        this.MonthSeason = MonthSeason;
        this.Year = Year;
        this.Status = Status;
        this.CreatedDate = CreatedDate;
        this.CreatedBy = CreatedBy;
    }
}

export function PrebookRequestSearchDocumentDto_$reflection() {
    return record_type("pogona_vendor_api_lib.PrebookDtos.PrebookRequestSearchDocumentDto", [], PrebookRequestSearchDocumentDto, () => [["id", string_type], ["CategoryManager", string_type], ["MonthSeason", string_type], ["Year", string_type], ["Status", string_type], ["CreatedDate", class_type("System.DateTime")], ["CreatedBy", string_type]]);
}

