import { Record, Union } from "../../fable-library-js.4.24.0/Types.js";
import { bool_type, option_type, list_type, int32_type, class_type, record_type, string_type, union_type } from "../../fable-library-js.4.24.0/Reflection.js";
import { PrebookItem_$reflection } from "./PrebookItem.fs.js";
import { PrebookItemError_$reflection } from "./PrebookItemError.fs.js";
import { map, bind, defaultArg } from "../../fable-library-js.4.24.0/Option.js";
import { isNullOrWhiteSpace, printf, toText } from "../../fable-library-js.4.24.0/String.js";
import { isEmpty, append, empty, singleton } from "../../fable-library-js.4.24.0/List.js";
import { utcNow } from "../../fable-library-js.4.24.0/Date.js";
import { map as map_1, delay, toArray } from "../../fable-library-js.4.24.0/Seq.js";
import { rangeDouble } from "../../fable-library-js.4.24.0/Range.js";

export class Month extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }
}

export function Month_$reflection() {
    return union_type("pogona_vendor_domain.prebook.Month", [], Month, () => [[], [], [], [], [], [], [], [], [], [], [], []]);
}

export class PrebookMonthSeason extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Month", "Season"];
    }
}

export function PrebookMonthSeason_$reflection() {
    return union_type("pogona_vendor_domain.prebook.PrebookMonthSeason", [], PrebookMonthSeason, () => [[["month", Month_$reflection()]], [["season", string_type]]]);
}

export class PrebookRequestStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PrebookInProgress", "PrebookSubmitForReview", "PrebookRejected", "PrebookMerchApproved"];
    }
}

export function PrebookRequestStatus_$reflection() {
    return union_type("pogona_vendor_domain.prebook.PrebookRequestStatus", [], PrebookRequestStatus, () => [[], [], [["reason", string_type]], []]);
}

export class PrebookRequestError extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PrebookRequestTestError", "PrebookCategoryManagerRequired", "PrebookCreatedByRequired", "KitDataRequiredWhenIsKit", "KitDataNotAllowedWhenNotIsKit"];
    }
}

export function PrebookRequestError_$reflection() {
    return union_type("pogona_vendor_domain.prebook.PrebookRequestError", [], PrebookRequestError, () => [[], [], [], [], []]);
}

export class ExcelProcessingStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PrebookExcelSubmitted", "ExcelProcessingInProgress", "ExcelProcessingCompleted", "ExcelProcessingFailed"];
    }
}

export function ExcelProcessingStatus_$reflection() {
    return union_type("pogona_vendor_domain.prebook.ExcelProcessingStatus", [], ExcelProcessingStatus, () => [[], [], [], [["reason", string_type]]]);
}

export class KitAdditionalData extends Record {
    constructor(ShipperUPC, VendorPartNumber) {
        super();
        this.ShipperUPC = ShipperUPC;
        this.VendorPartNumber = VendorPartNumber;
    }
}

export function KitAdditionalData_$reflection() {
    return record_type("pogona_vendor_domain.prebook.KitAdditionalData", [], KitAdditionalData, () => [["ShipperUPC", string_type], ["VendorPartNumber", string_type]]);
}

export class PrebookRequest extends Record {
    constructor(id, CategoryManager, MonthSeason, Year, CreatedBy, CreatedDate, PrebookErrors, Items, ItemErrors, ExcelPath, ExcelStatus, Status, IsKit, KitData) {
        super();
        this.id = id;
        this.CategoryManager = CategoryManager;
        this.MonthSeason = MonthSeason;
        this.Year = (Year | 0);
        this.CreatedBy = CreatedBy;
        this.CreatedDate = CreatedDate;
        this.PrebookErrors = PrebookErrors;
        this.Items = Items;
        this.ItemErrors = ItemErrors;
        this.ExcelPath = ExcelPath;
        this.ExcelStatus = ExcelStatus;
        this.Status = Status;
        this.IsKit = IsKit;
        this.KitData = KitData;
    }
}

export function PrebookRequest_$reflection() {
    return record_type("pogona_vendor_domain.prebook.PrebookRequest", [], PrebookRequest, () => [["id", class_type("System.Guid")], ["CategoryManager", string_type], ["MonthSeason", PrebookMonthSeason_$reflection()], ["Year", int32_type], ["CreatedBy", string_type], ["CreatedDate", class_type("System.DateTime")], ["PrebookErrors", list_type(PrebookRequestError_$reflection())], ["Items", list_type(PrebookItem_$reflection())], ["ItemErrors", list_type(PrebookItemError_$reflection())], ["ExcelPath", option_type(string_type)], ["ExcelStatus", option_type(ExcelProcessingStatus_$reflection())], ["Status", PrebookRequestStatus_$reflection()], ["IsKit", bool_type], ["KitData", option_type(KitAdditionalData_$reflection())]]);
}

export function PrebookRequestFunctions_getPrebookKitShipperUPC(request) {
    return defaultArg(bind((r) => map((k) => k.ShipperUPC, r.KitData), request), "");
}

export function PrebookRequestFunctions_getPrebookKitVendorPartNumber(request) {
    return defaultArg(bind((r) => map((k) => k.VendorPartNumber, r.KitData), request), "");
}

export function PrebookRequestFunctions_getExcelProcessingStatusForDisplay(status) {
    switch (status.tag) {
        case 1:
            return "Processing";
        case 2:
            return "Completed";
        case 3:
            return toText(printf("Failed: %s"))(status.fields[0]);
        default:
            return "Submitted";
    }
}

export function PrebookRequestFunctions_getPrebookRequestStatusForDisplay(status) {
    switch (status.tag) {
        case 1:
            return "Submitted for Review";
        case 2:
            return toText(printf("Rejected: %s"))(status.fields[0]);
        case 3:
            return "Approved";
        default:
            return "In Progress";
    }
}

export function PrebookRequestFunctions_categoryManagerRequired(request) {
    if (isNullOrWhiteSpace(request.CategoryManager)) {
        return singleton(new PrebookRequestError(1, []));
    }
    else {
        return empty();
    }
}

export function PrebookRequestFunctions_createdByRequired(request) {
    if (isNullOrWhiteSpace(request.CreatedBy)) {
        return singleton(new PrebookRequestError(2, []));
    }
    else {
        return empty();
    }
}

export function PrebookRequestFunctions_kitDataValidation(request) {
    const matchValue_1 = request.KitData;
    let matchResult;
    if (request.IsKit) {
        if (matchValue_1 == null) {
            matchResult = 0;
        }
        else {
            matchResult = 2;
        }
    }
    else if (matchValue_1 != null) {
        matchResult = 1;
    }
    else {
        matchResult = 2;
    }
    switch (matchResult) {
        case 0:
            return singleton(new PrebookRequestError(3, []));
        case 1:
            return singleton(new PrebookRequestError(4, []));
        default:
            return empty();
    }
}

export function PrebookRequestFunctions_validatePrebookRequest(request) {
    return append(PrebookRequestFunctions_categoryManagerRequired(request), append(PrebookRequestFunctions_createdByRequired(request), PrebookRequestFunctions_kitDataValidation(request)));
}

export function PrebookRequestFunctions_initNewPrebookRequest(id, categoryManager, monthSeason, year, createdBy, isKit, kitData) {
    return new PrebookRequest(id, categoryManager, monthSeason, year, createdBy, utcNow(), empty(), empty(), empty(), undefined, undefined, new PrebookRequestStatus(0, []), isKit, kitData);
}

export function PrebookRequestFunctions_initKitData(shipperUPC, vendorPartNumber) {
    return new KitAdditionalData(shipperUPC, vendorPartNumber);
}

export function PrebookRequestFunctions_yearsToDisplay(currentYear) {
    return toArray(delay(() => map_1((year) => year, rangeDouble(currentYear, 1, currentYear + 1))));
}

export function PrebookRequestFunctions_monthToString(month) {
    switch (month.tag) {
        case 1:
            return "February";
        case 2:
            return "March";
        case 3:
            return "April";
        case 4:
            return "May";
        case 5:
            return "June";
        case 6:
            return "July";
        case 7:
            return "August";
        case 8:
            return "September";
        case 9:
            return "October";
        case 10:
            return "November";
        case 11:
            return "December";
        default:
            return "January";
    }
}

export function PrebookRequestFunctions_monthsToArrayTextValue() {
    return [{
        Text: PrebookRequestFunctions_monthToString(new Month(0, [])),
        Value: new PrebookMonthSeason(0, [new Month(0, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(1, [])),
        Value: new PrebookMonthSeason(0, [new Month(1, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(2, [])),
        Value: new PrebookMonthSeason(0, [new Month(2, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(3, [])),
        Value: new PrebookMonthSeason(0, [new Month(3, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(4, [])),
        Value: new PrebookMonthSeason(0, [new Month(4, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(5, [])),
        Value: new PrebookMonthSeason(0, [new Month(5, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(6, [])),
        Value: new PrebookMonthSeason(0, [new Month(6, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(7, [])),
        Value: new PrebookMonthSeason(0, [new Month(7, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(8, [])),
        Value: new PrebookMonthSeason(0, [new Month(8, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(9, [])),
        Value: new PrebookMonthSeason(0, [new Month(9, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(10, [])),
        Value: new PrebookMonthSeason(0, [new Month(10, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(11, [])),
        Value: new PrebookMonthSeason(0, [new Month(11, [])]),
    }];
}

export function PrebookRequestFunctions_prebookHasErrors(request) {
    if (!isEmpty(request.PrebookErrors)) {
        return true;
    }
    else {
        return !isEmpty(request.ItemErrors);
    }
}

export function PrebookRequestFunctions_isPrebookMonth(monthSeason) {
    if (monthSeason.tag === 1) {
        return false;
    }
    else {
        return true;
    }
}

export function PrebookRequestFunctions_isPrebookSeason(monthSeason) {
    if (monthSeason.tag === 1) {
        return true;
    }
    else {
        return false;
    }
}

