<template>
  <core-view-template :title="title">
    <div>
      <show-impersonation />
      <CustTransSearch></CustTransSearch>
      <CustTransGrid
        :headers="headers"
        :custTrans="vpFinanceStore.custTransResult"
        :loading="loading"
        :hideDefaultFooter="false"
        :page="page"
        @update-page="page = $event"
        @load-cust-trans="handleGridLoadEvent"
      ></CustTransGrid>
      <v-row>
        <v-col cols="2">
          <ExcelExport
            :buttonText="'Export'"
            :loading="loading"
            :protectFile="true"
            @click="exportToExcel"
            @update:protectFile="localProtectFile = $event"
          ></ExcelExport>
        </v-col>
      </v-row>
    </div>
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import CustTransSearch from '@/components/vendorportal/CustTransSearch.vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import CustTransGrid from '@/components/vendorportal/CustTransGrid.vue';
import { useRoute } from 'vue-router/composables';
import { CustTransSearchParametersDto } from '@psp/pogona_vendor_api_lib/pogona_vendor_api_lib/src';
import { emptyList, toOption } from '@/utils/FableUtils.fs.js';
import ShowImpersonation from '@/components/vendorportal/ShowImpersonation.vue';
import { useTransGridShared } from '@/composables/TransGridShared';
import { getRouteValue } from '@/utils/Utils';
import ExcelExport from '@/components/vendorportal/ExcelExport.vue';
defineComponent({
  name: 'CustTransSearchHome',
});
const gridShared = useTransGridShared();
const headers = [
  gridShared.detailsHeader,
  gridShared.dataAreaIdHeader,
  gridShared.custAcctHeader,
  gridShared.createdOnHeader,
  gridShared.closedHeader,
  gridShared.invoiceHeader,
  gridShared.txtHeader,
  gridShared.amountCurHeader,
];
const route = useRoute();
const title = ref('Customer Transaction Search');
const vpFinanceStore = useVpFinanceStore();
const loading = ref(false);
const page = ref(1);
const localProtectFile = ref(true);
function exportToExcel() {
  vpFinanceStore.exportCustTransSearchToExcel(
    new CustTransSearchParametersDto(
      paymentRefRoute.value,
      false,
      '',
      custAcctRoute.value,
      emptyList(),
      fromDateRoute.value ? toOption(new Date(fromDateRoute.value)) : null,
      toDateRoute.value ? toOption(new Date(toDateRoute.value)) : null,
      0,
      500,
      true,
      false,
      transStatusRoute.value,
    ),
    localProtectFile.value,
  );
}
function handleGridLoadEvent(skip: number, take: number) {
  loadCustTrans(skip, take);
}
const paymentRefRoute = computed(() => {
  return getRouteValue(route.params.paymentRef);
});
const custAcctRoute = computed(() => {
  return getRouteValue(route.params.custAcct);
});
const invoiceRoute = computed(() => {
  return getRouteValue(route.params.invoice);
});
const fromDateRoute = computed(() => {
  return getRouteValue(route.params.fromDate);
});
const toDateRoute = computed(() => {
  return getRouteValue(route.params.toDate);
});
const transStatusRoute = computed(() => {
  return parseInt(getRouteValue(route.params.transStatus));
});
function loadCustTrans(skip: number, take: number) {
  if (paymentRefRoute.value !== 'undefined') {
    if (skip === 0) {
      page.value = 1;
    }
    loading.value = true;
    vpFinanceStore
      .searchCustTransactions(
        new CustTransSearchParametersDto(
          paymentRefRoute.value,
          false,
          '',
          custAcctRoute.value,
          emptyList(),
          fromDateRoute.value ? toOption(new Date(fromDateRoute.value)) : null,
          toDateRoute.value ? toOption(new Date(toDateRoute.value)) : null,
          skip,
          take,
          true,
          false,
          transStatusRoute.value,
        ),
      )
      .finally(() => {
        loading.value = false;
      });
  }
}
watch(
  [paymentRefRoute, custAcctRoute, invoiceRoute, fromDateRoute, toDateRoute, transStatusRoute],
  () => {
    loadCustTrans(0, 10);
  },
  { immediate: true },
);
onMounted(async () => {
  // loadCustTrans(0, 10);
});
</script>
