import { render, staticRenderFns } from "./SearchPrebookRequests.vue?vue&type=template&id=6f0a244a&scoped=true"
import script from "./SearchPrebookRequests.vue?vue&type=script&setup=true&lang=ts"
export * from "./SearchPrebookRequests.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SearchPrebookRequests.vue?vue&type=style&index=0&id=6f0a244a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f0a244a",
  null
  
)

export default component.exports