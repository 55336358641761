import { ref } from 'vue';
import { defineStore } from 'pinia';
import * as domain from '@psp/pogona_vendor_domain';
import * as prebookClient from '@/utils/PrebookApiClient.fs.js';
import * as prebookApi from '@psp/pogona_vendor_api_lib/pogona_vendor_api_lib/src';
import { VendorPortalFinanceInitArgs } from '@/utils/VendorPortalFinanceInitArgs';
import { ClientAuthenticatedAdalApiClient } from '@psp/pogona_adal_js';
import store from '@/store';
import { getFilenameFromContentDisposition } from '@/utils/Utils';
import FileSaver from 'file-saver';

function constructBaseUrl(initArgs: VendorPortalFinanceInitArgs): string {
  return initArgs.OverrideUrl.length > 0 ? initArgs.OverrideUrl : initArgs.Origin;
}

export function getPrebookClientOrThrow(
  getClient: (() => Promise<prebookApi.IPrebookApi>) | null,
): Promise<prebookApi.IPrebookApi> {
  if (getClient) return getClient();
  else throw new Error('Prebook client not available');
}

export const usePrebookStore = defineStore('prebookstore', () => {
  const prebookRequest = ref<domain.PrebookRequest | null>(null);
  const authClient = ref<ClientAuthenticatedAdalApiClient | null>(null);
  const getPrebookClient = ref<(() => Promise<prebookApi.IPrebookApi>) | null>(null);
  const seasonLookup = ref<domain.SeasonLookup | null>(null);
  const prebookTemplates = ref<prebookApi.NewItemTemplateFile[]>([]);
  const prebookSearchResults = ref<prebookApi.PrebookRequestSearchDocumentDto[]>([]);

  function init(args: VendorPortalFinanceInitArgs) {
    authClient.value = args.AuthClient;
    const getToken = async (client: ClientAuthenticatedAdalApiClient) => {
      return client.client.getToken() as Promise<string>;
    };
    const baseUrl = constructBaseUrl(args);
    getPrebookClient.value = async () => {
      return prebookClient.prebookApiClient(baseUrl, () => getToken(authClient.value));
    };
  }
  async function savePrebookRequest(prebookRequest: domain.PrebookRequest) {
    try {
      const client = await getPrebookClientOrThrow(getPrebookClient.value);
      await prebookClient.savePrebookRequest(prebookRequest, client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error saving prebook request error: ${JSON.stringify(error)}`,
        id: '4e814f94-86b8-4ab9-9258-b9d8f8a52e8f',
      });
      throw error;
    }
  }
  async function getPrebookRequest(prebookRequestId: string): Promise<domain.PrebookRequest> {
    try {
      const client = await getPrebookClientOrThrow(getPrebookClient.value);
      const prebook = await prebookClient.getPrebookRequest(prebookRequestId, client);
      prebookRequest.value = prebook;
      return prebook;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting prebook request error: ${JSON.stringify(error)}`,
        id: 'd3a8dc23-1ab9-4ff1-b7d5-d16e2fe3f354',
      });
      throw error;
    }
  }
  async function saveSeasonLookup(seasonLookup: domain.SeasonLookup) {
    try {
      const client = await getPrebookClientOrThrow(getPrebookClient.value);
      await prebookClient.saveSeasonLookup(seasonLookup, client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error saving season lookup error: ${JSON.stringify(error)}`,
        id: '76693cb5-02b0-4fdf-a414-32890ca77db9',
      });
      throw error;
    }
  }
  async function getSeasonLookup(): Promise<domain.SeasonLookup> {
    try {
      const client = await getPrebookClientOrThrow(getPrebookClient.value);
      const season = await prebookClient.getSeasonLookup(client);
      seasonLookup.value = season;
      return season;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting season lookup error: ${JSON.stringify(error)}`,
        id: '9d587ebe-a1ab-4001-a7dc-8c6246813ffa',
      });
      throw error;
    }
  }
  async function getPrebookTemplates() {
    try {
      const client = await getPrebookClientOrThrow(getPrebookClient.value);
      prebookTemplates.value = await prebookClient.callGetPrebookTemplates(client);
      return prebookTemplates.value;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting prebook templates error: ${JSON.stringify(error)}`,
        id: '5cb08321-e7dd-4e99-a70f-b09de05bba2f',
      });
      throw error;
    }
  }
  async function getPrebookTemplateByName(name: string) {
    try {
      const response = await authClient.value?.client.http.get(
        '/vendorapi/IPrebookApi/getPrebookTemplateByName/' + encodeURIComponent(name),
        { responseType: 'blob' },
      );
      const fileName = getFilenameFromContentDisposition(response.headers['content-disposition']);
      FileSaver.saveAs(response.data, fileName ?? 'prebook_template.xlsx');
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting prebook template by name error: ${JSON.stringify(error)}`,
        id: '89f19c6e-9983-4257-adcb-109dbf61b1c3',
      });
      throw error;
    }
  }
  async function uploadPrebookTemplate(file: File) {
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);
      await authClient.value?.client.http.post(
        '/vendorapi/IPrebookApi/uploadPrebookTemplate',
        formData,
      );
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error uploading prebook template error: ${JSON.stringify(error)}`,
        id: 'e05b2d9e-51be-423a-ad14-1120678056ac',
      });
      throw error;
    }
  }
  async function getCurrentPrebookTemplate() {
    try {
      const response = await authClient.value?.client.http.get(
        '/vendorapi/IPrebookApi/getCurrentPrebookTemplate',
        { responseType: 'blob' },
      );
      const fileName = getFilenameFromContentDisposition(response.headers['content-disposition']);
      FileSaver.saveAs(response.data, fileName ?? 'prebook_template.xlsx');
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting current prebook template error: ${JSON.stringify(error)}`,
        id: '1d330d3a-95bf-455d-bcc9-d39bcc0b3d2c',
      });
      throw error;
    }
  }
  async function searchPrebookRequests(searchParams: prebookApi.PrebookRequestSearchParametersDto) {
    try {
      const client = await getPrebookClientOrThrow(getPrebookClient.value);
      prebookSearchResults.value = await prebookClient.callSearchPrebookRequests(
        searchParams,
        client,
      );
      return prebookSearchResults.value;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error searching prebook requests error: ${JSON.stringify(error)}`,
        id: '710f8915-086d-4ea9-a51a-198e1718ff6f',
      });
      throw error;
    }
  }
  async function uploadPrebookItems(file: File, requestId: string) {
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);
      const response = await authClient.value?.client.http.post(
        `/vendorapi/IPrebookApi/uploadPrebookItems/${encodeURIComponent(requestId)}`,
        formData,
      );
      return response?.data;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error uploading prebook items error: ${JSON.stringify(error)}`,
        id: 'f9dcc4d3-5971-456e-839f-24c1b87b6941',
      });
      throw error;
    }
  }
  async function getPrebookExcelItems(requestId: string) {
    try {
      const response = await authClient.value?.client.http.get(
        `/vendorapi/IPrebookApi/getPrebookExcelItems/${encodeURIComponent(requestId)}`,
        { responseType: 'blob' },
      );
      const fileName = getFilenameFromContentDisposition(response.headers['content-disposition']);
      FileSaver.saveAs(response.data, fileName ?? 'prebook_items.xlsx');
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting prebook excel items error: ${JSON.stringify(error)}`,
        id: 'c5b7d6e1-0b9f-4e1a-a3a3-8d6c9c5a2e7f',
      });
      throw error;
    }
  }
  return {
    init,
    savePrebookRequest,
    getPrebookRequest,
    saveSeasonLookup,
    getSeasonLookup,
    getPrebookTemplates,
    getPrebookTemplateByName,
    uploadPrebookTemplate,
    getCurrentPrebookTemplate,
    searchPrebookRequests,
    uploadPrebookItems,
    getPrebookExcelItems,
    prebookRequest,
    seasonLookup,
    prebookTemplates,
    prebookSearchResults,
  };
});
