<template>
  <v-row>
    <v-col>
      <v-row v-if="isPclWriter">
        <v-btn @click="addNewOwner">Add New Owner</v-btn>
      </v-row>
      <v-row>
        <v-text-field
          label="Search by group, first, last name, or entity."
          v-model="searchText"
          clearable
          @click:clear="reset"
          v-debounce:300="reset"
          prepend-icon="search"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-data-table
          :headers="headers"
          :items="ownersData"
          :items-per-page="-1"
          :fixed-header="true"
          :loading="ownersGetInProgress"
          :footer-props="{
            disableItemsPerPage: true,
            itemsPerPageText: '',
          }"
          :options.sync="options"
          :disable-pagination="true"
          :server-items-length="ownersDataCount"
          class="elevation-1 scrollable"
          hide-default-footer
          item-key="OwnerId"
          id="ownerGroupsTable"
          @update:options="updateSortBy"
          height="100%"
        >
          <template v-slot:item.OwnerGroupId="{ value }">
            {{ getOwnerGroupName(value) }}
          </template>

          <template v-slot:item.PersonalPhone="{ value }">
            {{ value | phone }}
          </template>

          <template v-slot:item.Notes="{ item }">
            <pcl-owner-group-notes :notes="item.Notes"></pcl-owner-group-notes>
          </template>

          <template v-slot:item.Entity="{ item }">
            {{ formatEntities(item) }}
          </template>

          <template v-slot:item.edit="{ item }">
            <v-row>
              <v-icon @click="editRow(item)">edit</v-icon>
              <v-icon @click="deleteRow(item)">delete</v-icon>
            </v-row>
          </template>

          <template v-slot:body.append="{}">
            <tr>
              <td :colspan="headers.length + 10" class="ma-0 pa-0" style="height: 10px">
                <infinite-loading
                  v-if="ownersData && ownersData.length > 0"
                  @infinite="infiniteHandler"
                  :identifier="infiniteId"
                >
                </infinite-loading>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-row>

      <v-row>
        <v-col>
          <div class="my-3 ml-2">{{ ownersData.length }} / {{ ownersDataCount }}</div>
        </v-col>
        <v-col>
          <v-btn
            :disabled="exportOwnersInProgress"
            :loading="exportOwnersInProgress"
            @click="exportOwnerData"
            class="float-right"
            >Export</v-btn
          >
        </v-col>
      </v-row>

      <v-dialog v-model="deleteDialog" max-width="500">
        <v-card>
          <v-card-title>
            <span class="headline">Delete Owner</span>
          </v-card-title>
          <v-card-text
            >Are you sure you want to delete owner '{{ ownerToDelete.OwnerGroup }}'?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="beginDeleteRow"
              :disabled="ownersDeleteInProgress"
              :loading="ownersDeleteInProgress"
              >Yes</v-btn
            >
            <v-btn @click="deleteDialog = false">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog max-width="1024" v-model="editDialog">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Owner</span>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="10">
                    <v-autocomplete
                      v-model="ownerToEdit.OwnerGroupId"
                      label="Owner Group"
                      :items="ownerGroups"
                      item-text="OwnerGroupName"
                      item-value="OwnerGroupId"
                      clearable
                      :loading="getOwnerGroupsInProgress"
                      @change="validate('OwnerGroupId')"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      @click="editOwnerGroup"
                      :disabled="!ownerToEdit.OwnerGroupId || ownerToEdit.OwnerGroupId === 0"
                      >Edit</v-btn
                    >
                  </v-col>
                  <v-col cols="1">
                    <v-btn @click="newOwnerGroup">New</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6"
                ><v-text-field
                  v-model="ownerToEdit.FirstName"
                  label="First Name (required)"
                  autocomplete="off"
                  maxlength="50"
                  :error-messages="errorsMessages.FirstName"
                  @blur="validate('FirstName')"
                  @change="validate('FirstName')"
              /></v-col>
              <v-col cols="6"
                ><v-text-field
                  v-model="ownerToEdit.LastName"
                  label="Last Name (required)"
                  autocomplete="off"
                  maxlength="50"
                  :error-messages="errorsMessages.LastName"
                  @blur="validate('LastName')"
                  @change="validate('LastName')"
              /></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="ownerToEdit.PersonalEmail"
                  label="Personal Email"
                  autocomplete="off"
                  maxlength="50"
                  :error-messages="errorsMessages.PersonalEmail"
                  @blur="validate('PersonalEmail')"
                  @change="validate('PersonalEmail')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-subtitle>Entities</v-card-subtitle>
                  <v-card-text>
                    <data-multi-text-input :value.sync="entities" label="Entity" />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="ownerToEdit.OwnerEmail"
                  label="Owner Email"
                  autocomplete="off"
                  maxlength="50"
                  :error-messages="errorsMessages.OwnerEmail"
                  @blur="validate('OwnerEmail')"
                  @change="validate('OwnerEmail')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="9">
                <v-text-field
                  v-model="ownerToEdit.PersonalPhone"
                  label="Personal Phone"
                  maxlength="10"
                  :error-messages="errorsMessages.PersonalPhone"
                  @blur="validate('PersonalPhone')"
                  @change="validate('PersonalPhone')"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="ownerToEdit.PersonalPhoneExtension"
                  label="Personal Phone Ext"
                  maxlength="20"
                  :error-messages="errorsMessages.PersonalPhoneExtension"
                  @blur="validate('PersonalPhoneExtension')"
                  @change="validate('PersonalPhoneExtension')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  v-model="ownerToEdit.Notes"
                  counter="500"
                  maxlength="500"
                  :error-messages="errorsMessages.Notes"
                  @blur="validate('Notes')"
                  @change="validate('Notes')"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row justify="end">
              <v-btn
                @click="saveOwner"
                :loading="ownersSaveInProgress"
                :disabled="
                  editIsValid === false || ownersSaveInProgress || createOwnerInitialOpen === true
                "
                class="mr-3"
                >Save</v-btn
              >
              <v-btn @click="editDialog = false" :disabled="ownersSaveInProgress">Close</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editOwnerGroupDialog" max-width="500">
        <v-card>
          <v-card-title>
            <span class="headline"
              >{{ ownerGroupToEdit.OwnerGroupId === 0 ? 'Create' : 'Edit' }} Owner Group</span
            >
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Owner Group Name"
              v-model="ownerGroupToEdit.OwnerGroupName"
              autocomplete="off"
              @input="validateOwnerGroupName"
              :error-messages="saveOwnerGroupDisabledMessage"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="saveOwnerGroup"
              :disabled="updateOwnerGroupsInProgress || saveOwnerGroupDisabled"
              :loading="updateOwnerGroupsInProgress"
              >Save</v-btn
            >
            <v-btn @click="editOwnerGroupDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as pa from '@/store/modules/pcl/actions';
import * as ps from '@/store/modules/pcl/state';
import * as pm from '@/store/modules/pcl/mutations';
import * as pcldto from '@psp/pcldto';
import ODataProps from '@/utils/ODataProps';
import f from 'odata-filter-builder';
import * as Yup from 'yup';
import 'yup-phone-lite';
import PclOwnerGroupNotes from './PclOwnerGroupNotes.vue';

import InfiniteLoading from 'vue-infinite-loading';

const pclModule = namespace('pcl');

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
  components: {
    PclOwnerGroupNotes,
  },
})
export default class PclOwnerGroups extends Vue {
  storeFormSchema = Yup.object().shape({
    OwnerGroupId: Yup.number().label('Owner Group').required(),
    FirstName: Yup.string().max(50).label('First Name').required(),
    LastName: Yup.string().max(50).label('Last Name').required(),
    PersonalEmail: Yup.string().email().max(50).label('Personal Email').nullable().optional(),
    PersonalPhone: Yup.string()
      .matches(/^(\d{10}|)$/, 'Personal Phone must be 10 digits or blank')
      .max(10)
      .label('Personal Phone')
      .nullable()
      .optional(),
    PersonalPhoneExtension: Yup.string()
      .matches(/^(\d{1,20}|)$/, 'Personal Phone Ext must be 1-20 digits or blank')
      .max(20)
      .label('Personal Phone Ext')
      .nullable()
      .optional(),
    OwnerEmail: Yup.string().email().max(50).label('Owner Email').nullable().optional(),
    Entity: Yup.string().max(100).label('Entity').nullable().optional(),
    Notes: Yup.string().max(500).label('Notes').nullable().optional(),
  });

  private headers: any = [
    { value: 'OwnerGroupId', text: 'Owner Group' },
    { value: 'FirstName', text: 'First Name' },
    { value: 'LastName', text: 'Last Name' },
    { value: 'PersonalEmail', text: 'Personal Email' },
    { value: 'Entity', text: 'Entities', width: '300px' },
    { value: 'OwnerEmail', text: 'Owner Email' },
    { value: 'PersonalPhone', text: 'Personal Phone', width: '150px' },
    { value: 'PersonalPhoneExtension', text: 'Personal Phone Ext', width: '200px' },
    { value: 'Notes', text: 'Notes' },
  ];

  private defaultSort = { sortBy: ['OwnerGroup'], sortDesc: [false] };
  private options: any = { sortBy: ['OwnerGroup'], sortDesc: [false] };
  private infiniteId = +new Date();
  private lastCount = -1;
  private searchText: string | null = null;
  private isMounted = false;

  private editDialog = false;
  private editDialogIsNewOwner = false;
  private createOwnerInitialOpen = true;

  private deleteDialog = false;
  private editOwnerGroupDialog = false;

  private ownerToEdit: pcldto.pclOwner = {} as pcldto.pclOwner;
  private ownerToDelete: pcldto.pclOwner = {} as pcldto.pclOwner;
  private ownerGroupToEdit: pcldto.PclOwnerGroup = {} as pcldto.PclOwnerGroup;
  private saveOwnerGroupDisabled = true;
  private saveOwnerGroupDisabledMessage = [] as Array<string>;

  private isPclWriter = false;

  private entities = [] as Array<string>;

  private errorsMessages: ps.EditOwnerValidationMessages = new ps.EditOwnerValidationMessages();

  @pclModule.Action(pa.getOwners)
  getOwners!: (odata: ODataProps) => Promise<any>;
  @pclModule.Action(pa.updateOwner)
  updateOwner!: (owner: pcldto.pclOwner) => Promise<any>;
  @pclModule.Action(pa.createOwner)
  createOwner!: (owner: pcldto.pclOwner) => Promise<any>;
  @pclModule.Action(pa.deleteOwner)
  deleteOwner!: (ownerId: number) => Promise<any>;
  @pclModule.Action(pa.exportOwners)
  exportOwners!: (payload: ODataProps) => Promise<any>;
  @pclModule.Action(pa.getOwnerGroups)
  getOwnerGroups!: () => Promise<any>;
  @pclModule.Action(pa.updateOwnerGroup)
  updateOwnerGroup!: (ownerGroupToEdit: pcldto.PclOwnerGroup) => Promise<number>;

  @pclModule.State(ps.ownersData) ownersData!: pcldto.pclOwner[];
  @pclModule.State(ps.ownersDataOData) ownersDataOData!: ODataProps;
  @pclModule.State(ps.ownersGetInProgress) ownersGetInProgress!: boolean;
  @pclModule.State(ps.ownersSaveInProgress) ownersSaveInProgress!: boolean;
  @pclModule.State(ps.ownersDataSearchText) ownersDataSearchText!: string;
  @pclModule.State(ps.ownersDeleteInProgress) ownersDeleteInProgress!: boolean;
  @pclModule.State(ps.ownersDataCount) ownersDataCount!: number;
  @pclModule.State(ps.exportOwnersInProgress) exportOwnersInProgress!: boolean;
  @pclModule.State(ps.ownerGroups) ownerGroups!: pcldto.PclOwnerGroup[];
  @pclModule.State(ps.getOwnerGroupsInProgress) getOwnerGroupsInProgress!: boolean;
  @pclModule.State(ps.updateOwnerGroupsInProgress) updateOwnerGroupsInProgress!: boolean;

  @pclModule.Mutation(pm.setOwnersDataOData) setOwnersDataOData!: (payload: ODataProps) => void;
  @pclModule.Mutation(pm.setOwnersData) setOwnersData!: (payload: any) => void;
  @pclModule.Mutation(pm.setOwnersDataSearchText) setOwnersDataSearchText!: (
    payload: string | null,
  ) => void;

  public reset(doNotSetOrderBy = false) {
    setTimeout(async () => {
      this.infiniteId += 1;

      const ownersDataOData = new ODataProps();
      ownersDataOData.top = 50;
      ownersDataOData.skip = 0;

      if (doNotSetOrderBy !== true) {
        ownersDataOData.orderBy = [
          `${this.defaultSort.sortBy[0]} ${
            this.defaultSort.sortDesc[0] === true ? 'desc' : 'asc'
          }, OwnerId asc`,
        ];
      } else {
        ownersDataOData.orderBy = this.ownersDataOData.orderBy;
      }

      // clear the grid data
      this.setOwnersData({ data: [], skip: 0 });

      const trimmedSearch = this.searchText?.trim() ?? null;
      this.setOwnersDataSearchText(trimmedSearch);
      this.setOwnersDataOData(ownersDataOData);

      await this.getOwnerGroups();
      await this.getOwners(this.ownersDataOData);

      this.incrementSkip();
    }, 0);
  }

  public async updateSortBy() {
    if (this.isMounted == false) {
      this.setODataOrderBy();
    }

    if (this.options.sortBy.length === 1) {
      this.ownersDataOData.orderBy = [
        `${this.options.sortBy[0]} ${
          this.options.sortDesc[0] === true ? 'desc' : 'asc'
        }, OwnerId asc`,
      ];
    } else {
      this.ownersDataOData.orderBy = ['OwnerId asc'];
    }

    this.ownersDataOData.top = 50;
    this.ownersDataOData.skip = 0;

    this.infiniteId += 1;

    if (this.isMounted == true) {
      this.setOwnersDataOData(this.ownersDataOData);
      await this.getOwners(this.ownersDataOData);
    }
  }

  private async mounted() {
    this.isPclWriter = await this.$authApi.roleHasRights('PCLWriter');
    this.searchText = this.ownersDataSearchText;

    this.setODataOrderBy();

    if (this.isPclWriter === true && this.headers[this.headers.length - 1].value !== 'edit') {
      this.headers.push({ value: 'edit', text: 'Edit', width: '75px' });
    }

    this.isMounted = true;
  }

  private setODataOrderBy() {
    if (this.ownersDataOData.orderBy && this.ownersDataOData.orderBy.length === 1) {
      const splitOrder = this.ownersDataOData.orderBy[0].split(' ');
      this.options = { sortBy: [splitOrder[0]], sortDesc: [splitOrder[1] === 'desc'] };
    } else {
      this.options = Object.assign({}, this.defaultSort);
    }
  }

  public infiniteHandler($state: any) {
    this.getOwners(this.ownersDataOData)
      .then(() => {
        if (this.ownersData.length < this.ownersDataCount) {
          $state.loaded();
          this.incrementSkip();
        } else {
          $state.complete();
        }

        this.lastCount = this.ownersData.length;
      })
      .catch(() => {
        $state.complete();
      });
  }

  private incrementSkip() {
    this.ownersDataOData.skip += this.ownersDataOData.top;
    this.setOwnersDataOData(this.ownersDataOData);
  }

  public editRow(item: pcldto.pclOwner) {
    // only set the field if it's a different owner, otherwise we'll preserve their changes in memory
    if (item && (!this.ownerToEdit || item.OwnerId !== this.ownerToEdit.OwnerId)) {
      this.ownerToEdit = Object.assign({}, item);
    }
    this.entities = item.OwnerEntityMappings?.map(x => x.Entity);
    this.editDialogIsNewOwner = false;
    this.createOwnerInitialOpen = false;
    this.editDialog = true;
  }

  public deleteRow(item: pcldto.pclOwner) {
    // only set the field if it's a different owner, otherwise we'll preserve their changes in memory
    if (item) {
      this.ownerToDelete = Object.assign({}, item);
    }

    this.deleteDialog = true;
  }

  public addNewOwner() {
    // only re-init if we have to
    if (
      this.editDialogIsNewOwner !== true ||
      (this.ownerToEdit && this.ownerToEdit.OwnerId !== 0)
    ) {
      this.ownerToEdit = new pcldto.pclOwner(
        null,
        '',
        '',
        null,
        null,
        [],
        '',
        0,
        0,
        null,
        null,
        null,
      );
      this.editDialogIsNewOwner = true;
      this.createOwnerInitialOpen = true;
      this.entities = [];
    }
    this.editDialog = true;
  }

  public async saveOwner() {
    if (this.ownerToEdit) {
      this.fillOwnerEntityMappings();

      if (this.editDialogIsNewOwner === true) {
        await this.createOwner(this.ownerToEdit);
      } else {
        await this.updateOwner(this.ownerToEdit);
      }
      this.editDialog = false;
      this.ownerToEdit = new pcldto.pclOwner(
        null,
        '',
        '',
        null,
        null,
        [],
        '',
        0,
        0,
        null,
        null,
        null,
      );

      await this.reset(true);
    }
  }

  private fillOwnerEntityMappings() {
    const ownerEntityMappings = [] as pcldto.pclOwnerTypes.OwnerEntityMapping[];

    let eix = 0;
    for (const entity of this.entities) {
      if (
        this.editDialogIsNewOwner === true ||
        this.ownerToEdit.OwnerEntityMappings.length <= eix
      ) {
        ownerEntityMappings.push(new pcldto.pclOwnerTypes.OwnerEntityMapping(entity, 0, 0));
      } else {
        ownerEntityMappings.push(
          new pcldto.pclOwnerTypes.OwnerEntityMapping(
            entity,
            this.ownerToEdit.OwnerEntityMappings[eix].OwnerEntityMappingId,
            this.ownerToEdit.OwnerId,
          ),
        );
      }
      eix++;
    }

    this.ownerToEdit.OwnerEntityMappings = ownerEntityMappings;
  }

  public async beginDeleteRow() {
    await this.deleteOwner(this.ownerToDelete.OwnerId);
    this.deleteDialog = false;

    await this.reset(true);
  }

  private validate(field) {
    this.errorsMessages[field] = [];
    this.storeFormSchema.validateAt(field, this.validateEditOwnerState).catch(err => {
      this.errorsMessages[field] = err.errors;
    });
  }

  private get validateEditOwnerState() {
    return {
      OwnerGroupId: this.ownerToEdit.OwnerGroupId,
      FirstName: this.ownerToEdit.FirstName,
      LastName: this.ownerToEdit.LastName,
      PersonalEmail: this.ownerToEdit.PersonalEmail,
      PersonalPhone: this.ownerToEdit.PersonalPhone,
      PersonalPhoneExtension: this.ownerToEdit.PersonalPhoneExtension,
      OwnerEmail: this.ownerToEdit.OwnerEmail,
      Entity: '', //this.ownerToEdit.Entity,
      Notes: this.ownerToEdit.Notes,
    };
  }

  private get editIsValid() {
    this.createOwnerInitialOpen = false;

    // special handling for new owners
    if (
      this.editDialogIsNewOwner === true &&
      (!this.ownerToEdit.OwnerGroupId ||
        !this.ownerToEdit.FirstName ||
        this.ownerToEdit.FirstName.trim().length === 0 ||
        !this.ownerToEdit.LastName ||
        this.ownerToEdit.LastName.trim().length === 0)
    ) {
      return false;
    }

    if (this.errorsMessages) {
      for (const key of Object.keys(this.errorsMessages)) {
        if (this.errorsMessages[key] && this.errorsMessages[key].length > 0) {
          return false;
        }
      }
    }

    return true;
  }

  public async exportOwnerData() {
    await this.exportOwners(this.ownersDataOData);
  }

  public formatEntities(item) {
    if (item && item.OwnerEntityMappings && item.OwnerEntityMappings.length > 0) {
      return item.OwnerEntityMappings.map(x => x.Entity).join(', ');
    }
    return '';
  }

  private editOwnerGroup() {
    this.saveOwnerGroupDisabled = true;
    this.saveOwnerGroupDisabledMessage = [];

    if ((this.ownerToEdit?.OwnerGroupId ?? 0) > 0) {
      const selectedOwnerGroup = this.ownerGroups.filter(
        x => x.OwnerGroupId == this.ownerToEdit.OwnerGroupId,
      );
      if (this.ownerToEdit.OwnerGroupId && (selectedOwnerGroup?.length ?? 0) === 1) {
        this.ownerGroupToEdit = new pcldto.PclOwnerGroup(
          this.ownerToEdit.OwnerGroupId,
          selectedOwnerGroup[0].OwnerGroupName,
        );
      } else {
        throw new Error('Could not find selected owner group');
      }
    } else {
      throw new Error('Could not find selected owner group');
    }

    this.editOwnerGroupDialog = true;
  }

  private newOwnerGroup() {
    this.ownerGroupToEdit = new pcldto.PclOwnerGroup(0, '');
    this.saveOwnerGroupDisabled = true;
    this.saveOwnerGroupDisabledMessage = [];

    this.editOwnerGroupDialog = true;
  }

  private async saveOwnerGroup() {
    const updatedOwnerGroupId = await this.updateOwnerGroup(this.ownerGroupToEdit);
    await this.getOwnerGroups();

    this.ownerGroupToEdit.OwnerGroupId = updatedOwnerGroupId;

    this.editOwnerGroupDialog = false;
    this.saveOwnerGroupDisabled = true;
    this.saveOwnerGroupDisabledMessage = [];
  }

  private validateOwnerGroupName() {
    if ((this.ownerGroupToEdit?.OwnerGroupName?.trim() ?? '').length === 0) {
      this.saveOwnerGroupDisabled = true;
      this.saveOwnerGroupDisabledMessage = ['Owner group name required'];
      return;
    }

    // check to see if this already exists
    const ownerGroupCheck = this.ownerGroups.filter(
      x =>
        x.OwnerGroupId !== this.ownerGroupToEdit.OwnerGroupId &&
        x.OwnerGroupName.toLowerCase().trim() ===
          this.ownerGroupToEdit.OwnerGroupName.toLowerCase().trim(),
    );
    if (ownerGroupCheck && ownerGroupCheck.length > 0) {
      this.saveOwnerGroupDisabled = true;
      this.saveOwnerGroupDisabledMessage = [
        `Owner group '${ownerGroupCheck[0].OwnerGroupName}' already exists.`,
      ];
      return;
    }

    this.saveOwnerGroupDisabled = false;
    this.saveOwnerGroupDisabledMessage = [];
  }

  private getOwnerGroupName(ownerGroupId) {
    if ((this.ownerGroups?.length ?? 0) > 0) {
      const ownerGroup = this.ownerGroups.filter(x => x.OwnerGroupId === ownerGroupId);
      if ((ownerGroup?.length ?? 0) === 1) {
        return ownerGroup[0].OwnerGroupName;
      }
    }
    return '';
  }
}
</script>

<style lang="scss">
#ownerGroupsTable.scrollable .v-data-table__wrapper {
  margin-bottom: 0 !important;
  display: inline-table;
}
.error {
  color: red;
}
</style>
