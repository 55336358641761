import { onMounted, ref } from 'vue';
import { LegalEntityLookupDto } from '@psp/pogona_vendor_api_lib/pogona_vendor_api_lib/src';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import { toLower } from 'lodash';

export function useLegalEntityLookup(): any {
  const legalEntities = ref<LegalEntityLookupDto[]>([]);
  const vpFinanceStore = useVpFinanceStore();

  const getLegalEntityLookup = (legalEntityId: string) => {
    return legalEntities.value.find(le => le.LegalEntityId === legalEntityId)?.Name ?? '';
  };

  onMounted(async () => {
    legalEntities.value = await vpFinanceStore.getLegalEntityLookup();
  });

  return { legalEntities, getLegalEntityLookup };
}

export function useFinanceMapper(): any {
  function mapPOStatus(status: string) {
    // If status is BackOrder then map to 'Open order'
    return toLower(status) === 'backorder' ? 'Open order' : status;
  }

  return { mapPOStatus };
}
