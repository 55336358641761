<template>
  <core-view-template title="Prebook Request Search">
    <v-container>
      <v-row>
        <v-col>
          <v-text-field
            v-model="search"
            ref="searchTextBox"
            label="Pre-Book Search"
            clearable
            @keyup.enter="searchClicked"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn @click="searchClicked">Search</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="prebookStore.prebookSearchResults"
            :options.sync="options"
            :loading="loading"
            :footer-props="{ 'items-per-page-options': [] }"
            :server-items-length="computedServerItemsLength"
            hide-default-footer
          >
            <template v-slot:item.requestLink="{ item }">
              <router-link :to="{ name: 'prebook_prebookrequest', params: { prebookId: item.id } }">
                Details
              </router-link>
            </template>
            <template v-slot:item.CreatedDate="{ item }">
              <span class="no-wrap">{{ formatters.formatDatetime(item.CreatedDate, false) }}</span>
            </template>
            <template v-slot:item.Status="{ item }">
              <span class="no-wrap">{{ item.Status }}</span>
            </template>
            <template v-slot:item.MonthSeason="{ item }">
              <span class="no-wrap">{{ item.MonthSeason }}</span>
            </template>
            <template v-slot:item.CategoryManager="{ item }">
              <span class="no-wrap">{{ item.CategoryManager }}</span>
            </template>
            <template v-slot:footer="{ props }">
              <v-row class="pa-3" align="center" justify="end">
                <v-btn
                  v-if="props.pagination.page > 1"
                  text
                  color="primary"
                  :disabled="props.pagination.page === 1"
                  @click="goToPrevPage()"
                >
                  Prev
                </v-btn>
                <span class="mr-4">Page {{ props.pagination.page }}</span>
                <v-btn
                  v-if="!disableNext"
                  text
                  color="primary"
                  :disabled="disableNext"
                  @click="goToNextPage()"
                >
                  Next
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { usePrebookStore } from '@/stores/prebookstore';
import * as prebookApi from '@psp/pogona_vendor_api_lib/pogona_vendor_api_lib/src';
import { useFormatters } from '@/composables/formatters';
const prebookStore = usePrebookStore();
const formatters = useFormatters();
const search = ref('');
const loading = ref(false);
const options = ref({
  page: 1,
  itemsPerPage: 10,
  sortBy: [],
  sortDesc: [],
});
const searchTextBox = ref<HTMLInputElement | null>(null);
const headers = [
  { text: 'Request', value: 'requestLink', sortable: false },
  { text: 'Category Manager', value: 'CategoryManager', sortable: false },
  { text: 'Month/Season', value: 'MonthSeason', sortable: false },
  { text: 'Year', value: 'Year', sortable: false },
  { text: 'Status', value: 'Status', sortable: false },
  { text: 'Created Date', value: 'CreatedDate', sortable: false },
  { text: 'Created By', value: 'CreatedBy', sortable: false },
];
const disableNext = computed(() => {
  // If we have total records:
  return prebookStore.prebookSearchResults.length < options.value.itemsPerPage;
});

// Example "Prev" / "Next" methods
function goToPrevPage() {
  if (options.value.page === 1) return;
  options.value.page -= 1;
}

function goToNextPage() {
  if (disableNext.value) return;
  options.value.page += 1;
}
async function searchClicked() {
  options.value.page = 1;
  await searchRequests();
}
async function searchRequests() {
  try {
    loading.value = true;

    // Extract pagination from the options object
    const { page, itemsPerPage } = options.value;
    const skip = (page - 1) * itemsPerPage;
    const take = itemsPerPage;

    // Build our search parameters
    const searchParams = prebookApi.initPrebookRequestSearchParametersDto();

    // Now pass Skip and Take to the store function
    await prebookStore.searchPrebookRequests({
      ...searchParams,
      Search: search.value === null || search.value === '' ? '*' : `${search.value}*`,
      Skip: skip,
      Take: take,
    } as prebookApi.PrebookRequestSearchParametersDto);
  } finally {
    loading.value = false;
  }
}
watch(
  () => options.value,
  () => {
    // We only want to run a new search if the user changes page, page size, or sorting
    // Use `deep: true` so changes in nested properties are detected
    console.log('options', options.value);
    searchRequests();
  },
  { deep: true },
);
const computedServerItemsLength = computed(() => {
  return prebookStore.prebookSearchResults.length < options.value.itemsPerPage
    ? prebookStore.prebookSearchResults.length
    : 9999;
});
onMounted(() => {
  searchTextBox.value?.focus();
});
</script>
<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap;
}
</style>
